import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import Market from "../Market/Market";
import "./TradeHistory.css";
import History from "./TradeHistory/History/History";
import CandlestickCharts from "../CandlestickCharts/CandlestickCharts";
import CryptoCandlestickCharts from "../TradePage/CryptoCandlestickCharts/CryptoCandlestickCharts";
import MetalCandlestickCharts from "../TradePage/MetalCandlestickCharts/MetalCandlestickCharts";
import ForexCandlestickCharts from "../TradePage/ForexCandlestickCharts/ForexCandlestickCharts";
const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [TradeData, setTradeData] = useState([]);

  const [HistoryDataUpdate, setHistoryDataUpdate] = useState();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/trade/market/crypto/currency/mobile/filter/view`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data) {
          fetchData(data?.data[0]);
        }

      });
  }, []);

  const fetchData = async (cryptoData) => {
    if (cryptoData) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/trade/market/crypto/currency/view/${cryptoData?.crypto_currency_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
        const MarketSymbol = (data?.data?.Symbol)+(data?.data?.currency);
        setTradeData({MarketData:data?.data, marketSymbol:MarketSymbol, Market:"Crypto"});
        setPageLoading(false);
        });
    }
  };

  const handleTradeChart = (data) => {
    setLoading(true);
    setTradeData(data);
    setTimeout(function () {
      setLoading(false);
    }, 800);
  };

  if (pageLoading) {
    return;
  }
  return (
    <>
      <section className="user-dashboard-section">
        <div className="row">
          <div className="col-3">
            <Market handleTradeChart={handleTradeChart} />
          </div>
          {loading ? (
            ""
          ) : (
            <>
            {TradeData?.Market === "Crypto" && <CryptoCandlestickCharts TradeData={TradeData} />}
            {TradeData?.Market === "Forex" && <ForexCandlestickCharts TradeData={TradeData} />}
            {TradeData?.Market === "Metal" && <MetalCandlestickCharts TradeData={TradeData} />}
            </>
          )}
        </div>
      </section>
      <section className="trade-order-history-section">
        {/* <div className="trade-order-history-menu-area">
            <div className="trade-order-history-menu">
              <p
                className='active'
              >
                History
              </p>
            </div>
          </div> */}
        <History HistoryDataUpdate={HistoryDataUpdate} />
      </section>
    </>
  );
};

export default Dashboard;
