import axios from "axios";
import dateFormat from "dateformat";
//gets 500 candle sticks
export const GetCandles = (currentTimeFrame, currentCoin) => {
    const date = new Date();
    const MinusDate = new Date(
      date - 30 * 24 * 60 * 60 * 1000
    );
    // AAPL
    let lastDate = dateFormat(date, "yyyy-mm-dd");
    let preDate = dateFormat(MinusDate, "yyyy-mm-dd");
   const url = `https://api.polygon.io/v2/aggs/ticker/${currentCoin}/range/${currentTimeFrame}/minute/${preDate}/${lastDate}?adjusted=true&sort=asc&limit=1000&apiKey=ipIR7s4zvdfonZVeqEIN_V_3oixreM9a`
  return axios.get(url);
};

//gets current candle stick info from binance
export const GetLiveCandle = (currentTimeFrame, currentCoin) => {
  let symbol_LowerCase = currentCoin;
  let symbol = symbol_LowerCase.toLowerCase();
  return (
    "wss://stream.binance.com:9443/ws/" + symbol + "@kline_" + currentTimeFrame
  );
};
