import React from "react";
import image from "./credit_spotlight_izzy_the_desk_hp-promo.jpg";
const SystematicTrading = () => {
  return (
    <>
      <div className="RTF">
        <section className="bg-color-gray-white-gradient py-4 py-lg-5">
          <div className="container mw-1200 mt-3 mb-3">
            <div className="row hp-featured-row-center">
              <div className="col-6">
                <a
                  href="#"
                  className="margin-content-image-left col-12 col-featured-image order-first bg-size-cover bg-size-cover-md figure mb-4 mb-lg-0 mnh-lg-315 js-sr-right px-sm-0 px-md-0 px-lg-0"
                  data-sr-id="23"
                >
                  <img
                    data-src={image}
                    className="content-promo-image"
                    alt=" of Izzy Conlin, Managing Director, Head of Institutional U.S. Credit "
                    src={image}
                  />
                </a>
              </div>
              <div className="col-6">
                <div
                  className="col-12 col-featured-content font-slatepro-light content-left js-sr-left"
                  data-sr-id="18"
                >
                  <div>
                    <div className="display-size-11">
                      <h4>
                        <span style={{ color: "#002d5b" }}>
                          How Big Can Systematic Trading Get in Credit?
                        </span>
                      </h4>
                      <p>
                        <span dir="ltr">
                          Izzy Conlin, our Head of U.S. Institutional Credit,
                          recently shared her thoughts around the growing role
                          of systematic trading in
                          <a href="/our-markets/institutional/credit/corporate-bonds/">
                            credit
                          </a>
                          markets, and how reliable composite pricing sources,
                          more accessible data and advances in technology are
                          shaping a new era of innovation in these markets.
                        </span>
                      </p>
                    </div>
                    <footer className="mt-4">
                      <a
                        href="#"
                        aria-label="The Desk article"
                        className="btn btn-dark-blue"
                      >
                        <div className="d-flex align-items-center">
                          READ ARTICLE
                        </div>
                      </a>
                    </footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SystematicTrading;
