import React, { useContext, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import logo from "./../../../Logo.jpg";
import "./Auth.css";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";

const LoginForm = () => {
  const { LoginWithEmail } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const userFrom = location.state?.from?.pathname || "/user/trade";

  const [errorMessage, setErrorMessage] = useState({});
  const refSubmitDis = useRef();

  const handleLogin = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    setErrorMessage();
    fetch(`${process.env.REACT_APP_API}/api/user/auth/login`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(InpuData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          if (data?.errors) {
            setErrorMessage(data.errors);
          } else {
            setErrorMessage(data);
          }
          refSubmitDis.current.removeAttribute("disabled");
        } else {
          const user = data;
          localStorage.setItem("robicon_id", JSON.stringify(user.data));
          const expires = new Date(
            Date.now() + 365 * 24 * 60 * 60 * 1000
          ).toUTCString();
          document.cookie = `robicon_token=OiJpcWJhbDExMSIsInVzZXJfaWQiOiI2M2VhNmE3MmJ1c2VyX25hbWMzODM5NX0VzZXJfaWQiOiI2M2InVzZXJfaWQiOiI2M2VhNmE3MmU4N2U5ZWJkNGM; expires=${expires}; path=/`;
          event.target.reset();
          if (user.data) {
            LoginWithEmail(user.data);
            navigate(userFrom, { replace: true });
          }
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  return (
    <>
      <section className="body-section">
        <section className="login-section">
          <div className="login-area">
            <div className="login-card">
              <div className="login-img">
                <Link to="/">
                  {/* <LazyLoadImage alt="img" effect="blur" src={logo} /> */}
                </Link>
              </div>
              {errorMessage?.success === false ? (
                <span className="text-danger text-start ms-2 pb-5 d-block">
                  {errorMessage.banMessage}
                </span>
              ) : (
                ""
              )}
              <div className="login-form-area">
                <form onSubmit={handleLogin}>
                  <div className="input-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email address"
                    />
                    <span className="text-danger pt-1">
                      {errorMessage?.email && errorMessage?.email?.msg}{" "}
                    </span>
                    <span className="text-danger pt-1">
                      {errorMessage?.EmailError && errorMessage?.message}{" "}
                    </span>
                  </div>
                  <div className="input-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                    />
                    <span className="text-danger pt-1">
                      {errorMessage?.password && errorMessage?.password?.msg}{" "}
                    </span>
                    <span className="text-danger pt-1">
                      {errorMessage?.passwordError && errorMessage?.message}{" "}
                    </span>
                  </div>

                  <div className="remember">
                    <input type="checkbox" name="" id="remember" />
                    <label htmlFor="remember"> Remember Me</label>
                  </div>

                  <div className="forget">
                    <Link to="/forgot/password">Forget password? </Link>
                  </div>

                  <div className="submit-btn-area">
                    <button
                      className="btn btn-dark-blue"
                      type="submit"
                      ref={refSubmitDis}
                    >
                      <div className="d-flex align-items-center">Login</div>
                    </button>
                  
                  </div>
                  <div className="submit-btn-area">
                    <Link to="/signup" className="btn btn-dark-blue">
                      <div className="d-flex align-items-center">Signup</div>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <Link to="/" className="home-btn">
            Home
          </Link> */}
        </section>
      </section>
    </>
  );
};

export default LoginForm;
