import React from "react";
import Header from "./Header/Header";
import { Outlet } from "react-router-dom";
import "./MainMaster.css";
const MainMaster = () => {
  return (
    <>
      <section className="home-main-area">
        <Header></Header>
        <Outlet></Outlet>
      </section>
    </>
  );
};

export default MainMaster;
