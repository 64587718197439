import React from "react";
import "./TopBannar.css";
import videos from "./homepage-video_nov-2020.mp4";
const TopBannar = () => {
  return (
    <>
      <section
        className="jumbotron jumbotron-3 bg-size-cover align-content-between d-flex flex-wrap position-relative w-100 mb-0 p-0 js-sr-intro-1 mnh-600 justify-content-between flex-column overflow-hidden"
        data-sr-id="0"
       
      >
        <video
          id="jumbotron-video"
          className="position-absolute h-auto w-auto mnh-100 mnw-100"
          autoPlay
          muted
          loop
        >
          <source
            src={videos}
            type="video/mp4"
          />
        </video>
        <button
          className="btn btn-link btn-pause js-pause-jumbotron-video color-white text-uppercase text-underline p-0 pause"
          aria-label="Pause Video"
        >
          <span className="pause-text">Pause Video</span>
          <span className="play-text">Play Video</span>
        </button>
        <button
          className="btn btn-link btn-pause-icon js-pause-jumbotron-video p-0 pause"
          aria-label="Pause Video"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            className="pause-icon"
          >
            <path
              d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18 11.5625H16C14.8954 11.5625 14 12.5419 14 13.75V31.25C14 32.4581 14.8954 33.4375 16 33.4375H18C19.1046 33.4375 20 32.4581 20 31.25V13.75C20 12.5419 19.1046 11.5625 18 11.5625ZM28 11.5625H26C24.8954 11.5625 24 12.5419 24 13.75V31.25C24 32.4581 24.8954 33.4375 26 33.4375H28C29.1046 33.4375 30 32.4581 30 31.25V13.75C30 12.5419 29.1046 11.5625 28 11.5625Z"
              fill="#0076A8"
            ></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            className="play-icon"
          >
            <path
              d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.8508 33.8046C15.3892 33.8046 15.015 33.3942 15.015 32.8879V12.7212C15.0151 12.3936 15.1747 12.091 15.4335 11.9274C15.6923 11.7639 16.0111 11.7642 16.2696 11.9283L32.1504 22.0117C32.4082 22.1757 32.5669 22.4778 32.5669 22.8046C32.5669 23.1314 32.4082 23.4335 32.1504 23.5975L16.2696 33.6808C16.1424 33.7619 15.9979 33.8045 15.8508 33.8046Z"
              fill="#0076A8"
            ></path>
          </svg>
        </button>

        <div
          className="TopBannar-container d-flex flex-column align-self-center z-index-1 justify-content-center pt-md-200 mb-sm-150 mb-md-5 home-container"
          style={{paddingTop: "50px"}}
          id="homeContainerFlex"
        >
          <h1
            className="display-size-1 display-size-1-sm text-transform-none color-white ls-04 mb-3 js-sr-intro-2"
            data-sr-id="3"
           
          >
            It's All On Tradeweb
          </h1>

          <div className="row">
            <div className="col-12 col-sm-8">
              <p
                className="display-size-8 display-size-88 display-size-10-sm font-slatepro-medium text-transform-none color-white ls-05 js-sr-intro-2"
                data-sr-id="4"
               
              >
                More innovative and dynamic ways to trade.
              </p>
            </div>
          </div>

          <div
            className="home-button mt-sm-2 mt-md-80 js-sr-intro-2-1"
            data-sr-id="5"
           
          >
            <a href="#" className="btn btn-marine-blue">
              <span className="d-flex align-items-center"> WHO WE ARE </span>
            </a>
          </div>
        </div>

        <footer className="position-relative w-100">
          <div
            className="position-relative py-lg-4 px-4 js-sr-intro-3b-1"
            data-sr-id="11"
           
          >
            <div className="position-absolute-bl w-100 h-100 bg-color-turquoise-green-gradeint opacity-04"></div>
            <div className="TopBannar-container display-size-10 display-size-10-sm color-white py-2">
              <div className="row align-items-center">
                <div className="RTF col-12 col-lg-8 border-lg-right border-md-bottom border-white pt-3 pt-lg-4 pb-2 pb-lg-3 pc-mb-0 font-slatepro display-size-8 text-uppercase color-white ls-05 announcement-text">
                  <p className="h4">
                    <span style={{color:"#ffffff"}}>
                      RECORD TOTAL TRADING VOLUME OF $56.1 TRILLION AND RECORD
                      ADV OF $2.63 TRILLION REPORTED IN SEPTEMBER
                    </span>
                  </p>
                </div>

                <div className="col-12 col-lg-4 pt-4 pb-2 pb-lg-3 pl-lg-5">
                  <a
                    className="btn btn-link p-0 text-uppercase color-white display-size-11 font-slatepro no-underline ls-04"
                    href="#"
                  >
                    VIEW REPORT
                    <span className="icons arrow align-1 ml--2" aria-hidden="true">
                      Go
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="position-relative h-58 text-center js-sr-intro-3b"
            data-sr-id="10"
            
          >
            <div className="position-absolute-bl h-100 w-100 bg-color-white "></div>
            <a
              href="#"
              role="button"
              className="small icons color-marine-blue position-absolute-l-50 my-4 animated infinite slow bounce js-scroll-down scroll-down"
            >
              Down
            </a>
          </div> */}
        </footer>
      </section>
    </>
  );
};

export default TopBannar;
