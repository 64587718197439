import React from "react";
import "./Incorporate.css";
import image from "./hppromo_nr_tw-ftse_wgbi_1728x1000.webp";
const Incorporate = () => {
  return (
    <>
      <div className="RTF">
        <section className="bg-color-gray-white-gradient py-4 py-lg-5">
          <div className="container mw-1200 mt-3 mb-3">
            <div className="row hp-featured-row-center">
                
             <div className="col-6">
             <div
                className="col-12 col-featured-content font-slatepro-light js-sr-right"
                data-sr-id="23"
              >
                <div>
                  <div className="display-size-11">
                    <h4>
                      <span style={{ color: "#001f3e" }}>
                        WGBI to Incorporate Tradeweb FTSE Benchmark Closing
                        Prices
                      </span>
                    </h4>
                    <p>
                      We recently announced that Tradeweb FTSE benchmark closing
                      prices for U.S. Treasuries, UK Gilts, and European
                      Government Bonds will now be included in FTSE Russell, An
                      LSEG Business' premiere World Government Bond Index
                      (WGBI). It’s another step in our commitment to develop the
                      next generation of fixed income pricing and index trading
                      products.
                    </p>
                  </div>
                  <footer className="mt-4">
                    <a
                      href="#"
                      aria-label="Read more about Tradeweb FTSE Benchmark Closing Prices"
                      className="btn btn-dark-blue"
                    >
                      <div className="d-flex align-items-center">
                        READ NEWS RELEASE
                      </div>
                    </a>
                  </footer>
                </div>
              </div>
             </div>
             <div className="col-6">
                <a
                href="#"
                className="margin-content-image-right col-12 col-featured-image order-first order-lg-last bg-size-cover bg-size-cover-md figure mb-4 mb-lg-0 js-sr-left d-block mnh-lg-315 px-sm-0 px-md-0 px-lg-0"
                data-sr-id="18"
              >
                <img
                  data-src={image}
                  className="content-promo-image"
                  alt="WGBI to Incorporate Tradeweb FTSE Benchmark Closing Prices News Release"
                  src={image}
                />
              </a>
                </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Incorporate;
