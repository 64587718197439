import React, { useEffect, useState } from "react";

const GatewaysSelectForm = ({ data }) => {
    const [SingleData, setSingleData] = useState([]);
    useEffect(() => {
        if(data?.gateway_id){
            fetch(
                `https://api.rdlgex.com/api/user/payment/gateways/manual/view/${data?.gateway_id}`,
                {
                  method: "GET",
                }
              )
                .then((res) => res.json())
                .then((data) => {
                    setSingleData(data?.data);
                });
        }
    }, []);
  return (
    <>
      <option value={SingleData?._id} key={SingleData?._id}>
        {SingleData?.networkType}
      </option>
    </>
  );
};

export default GatewaysSelectForm;
