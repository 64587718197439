import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import "./KYC.css";
const KYC = () => {
  const { authUser } = useContext(AuthContext);

  const [userfront_page_img, setUserfront_page_img] = useState("");
  const [userback_page_img, setUserback_page_img] = useState("");
  const refSubmitDis = useRef();
  const [errorMessage, setErrorMessage] = useState([]);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const [results, setResults] = useState({});

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `${process.env.REACT_APP_API}/api/kyc/verify/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          setData(data.data);
        });
    }
  }, [results, authUser?._id]);

  const handlefront_page_img = (e) => {
    setErrorMessage("");
    setUserfront_page_img(e.target.files[0]);
  };
  const handleback_page_img = (e) => {
    setErrorMessage("");
    setUserback_page_img(e.target.files[0]);
  };

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    setErrorMessage();
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);

    const storeData = {
      ...InpuData,
      user_id: authUser?._id,
      front_page_img: userfront_page_img,
      back_page_img: userback_page_img,
    };

    if (storeData.front_page_img === "") {
      setErrorMessage({
        front_page_img: { msg: "please choose Front part image" },
      });
      refSubmitDis.current.removeAttribute("disabled");
    } else if (storeData.back_page_img === "") {
      setErrorMessage({
        back_page_img: { msg: "please choose Back part image" },
      });
      refSubmitDis.current.removeAttribute("disabled");
    } else {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API}/api/kyc/verify/store`,
          storeData,
          config
        )
        .then((data) => {
          event.target.reset();
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setResults(data);
          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          refSubmitDis.current.removeAttribute("disabled");
          if (error?.response?.data?.errors) {
            setErrorMessage(error?.response?.data?.errors);
          } else {
            setErrorMessage(error?.response?.data);
          }
        });
    }
  };

  const [userDataLoading, setUserDataLoading] = useState(true);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/auth/view/single/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
          setUserDataLoading(false);
        });
    }
  }, [authUser?._id]);

  if (loading || userDataLoading) {
    return;
  }

  if (data?.status === 0) {
    return (
      <>
        <div className="user-deposit-section">
          <div className="container">
            <div className="card h-100">
              <div className="card-body">
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className="mb-3 text-primary">
                      KYC verification Information
                    </h6>
                  </div>

                  <div className="user-kyc-data">
                    <div className="user-kyc-data-item">
                      <h5> Full Name:</h5>
                      <p>{data?.user_name}</p>
                    </div>
                    <div className="user-kyc-data-item">
                      <h5> Certification:</h5>
                      <p>{data?.type}</p>
                    </div>
                    <div className="user-kyc-data-item">
                      <h5> Front Part Photo:</h5>
                      <p>
                        <a
                          href={`${process.env.REACT_APP_API}/${data?.front_img}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click Here
                        </a>
                      </p>
                    </div>
                    <div className="user-kyc-data-item">
                      <h5> Back Part Photo:</h5>
                      <p>
                        <a
                          href={`${process.env.REACT_APP_API}/${data?.back_img}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click Here
                        </a>
                      </p>
                    </div>
                    {data?.ssn && (
                      <div className="user-kyc-data-item">
                        <h5> SSN:</h5>
                        <p>{data?.user_name}</p>
                      </div>
                    )}

                    <div className="user-kyc-data-item">
                      <h5> Status:</h5>
                      <p className="badge bg-warning py-2 text-dark user-kyc-data-status">
                        Pending
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (data?.status === 1 && userData?.kv) {
    return (
      <>
        <div className="user-deposit-section">
          <div className="container">
            <div className="card h-100">
              <div className="card-body">
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className="mb-3 text-primary">KYC verified</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="user-deposit-section">
          <div className="container">
            <div className="card h-100">
              <div className="card-body">
                <form onSubmit={handleSubmitData}>
                  <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 className="mb-3 text-primary">KYC</h6>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Full Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="exampleFormControlInput2"
                          aria-describedby="emailHelp"
                        />
                        <span className="text-danger pt-2">
                          {errorMessage?.name && errorMessage?.name?.msg}
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Certification
                        </label>
                        <select
                          class="form-control cmn--form--control"
                          name="type"
                        >
                          <option value="" disabled selected>
                            Select One
                          </option>
                          <option value="Passport">Passport</option>
                          <option value="NID">NID</option>
                          <option value="Driving License">
                            Driving License
                          </option>
                        </select>
                        <span className="text-danger pt-2">
                          {errorMessage?.type && errorMessage?.type?.msg}
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Front Part Photo
                        </label>
                        <br />
                        <label className="deposit-input-file">
                          <b className="deposit-input-file-btn deposit-input-file-btn-primary">
                            <i className="deposit-input-file-icons"></i> Choose
                            a File
                          </b>
                          <input
                            onChange={handlefront_page_img}
                            type="file"
                            className="deposit-input-file-fileInput"
                            accept=" .jpg,  .jpeg,  .png, "
                          />
                        </label>
                        <br />
                        <span className="text-danger pt-2">
                          {errorMessage?.front_page_img &&
                            errorMessage?.front_page_img?.msg}{" "}
                        </span>
                        <pre className="text--base mt-1">
                          Supported mimes: jpg,jpeg,png
                        </pre>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Back Part Photo
                        </label>
                        <br />
                        <label className="deposit-input-file">
                          <b className="deposit-input-file-btn deposit-input-file-btn-primary">
                            <i className="deposit-input-file-icons"></i> Choose
                            a File
                          </b>
                          <input
                            onChange={handleback_page_img}
                            type="file"
                            className="deposit-input-file-fileInput"
                            accept=" .jpg,  .jpeg,  .png, "
                          />
                        </label>
                        <br />
                        <span className="text-danger pt-2">
                          {errorMessage?.back_page_img &&
                            errorMessage?.back_page_img?.msg}{" "}
                        </span>
                        <pre className="text--base mt-1">
                          Supported mimes: jpg,jpeg,png
                        </pre>
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          SSN <span>(optional)</span>
                        </label>
                        <input
                          type="text"
                          name="ssn"
                          className="form-control"
                          id="exampleFormControlInput2"
                          aria-describedby="emailHelp"
                        />
                        <pre className="text--base mt-1">
                          Note: only USA and canadian citizen
                        </pre>
                        <span className="text-danger pt-2">
                          {errorMessage?.ssn && errorMessage?.ssn?.msg}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="user-submit-btn">
                    <button
                      type="submit"
                      id="submit"
                      className="btn btn-primary px-5 fw-normal d-block mt-1"
                      ref={refSubmitDis}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default KYC;
