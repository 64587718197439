import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import TradeCountdown from "../TradeCountdown/TradeCountdown";
import dateFormat from "dateformat";
const ForexTrade = ({ TradeData, getLivePrice, setHistoryDataUpdate }) => {
  const { authUser } = useContext(AuthContext);
  const { MarketData, marketSymbol } = TradeData;
  const livePrice = getLivePrice?.current;
  // store data
  const refSubmitDisBuy = useRef();
  const refSubmitDisSell = useRef();

  const [AmountValue, setAmountValue] = useState('');
  const [TimeValue, setTimeValue] = useState('');

  const [errorMessage, setErrorMessage] = useState([]);

  const [countDownModalShow, setCountDownModalShow] = useState(false);
  
  const [results, setResults] = useState([]);


  const handleSubmitData = (Direction) => {
    if (livePrice > 0) {
      const storeData = {
        user: authUser,
        pairs: marketSymbol,
        amount:AmountValue,
        thradeSetting_id:TimeValue,
        crypto_id: MarketData?._id,
        direction: Direction,
        Crypto_price:livePrice,
        market: "forex",
      };
      setErrorMessage();
      refSubmitDisBuy.current.setAttribute("disabled", true);
      refSubmitDisSell.current.setAttribute("disabled", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API}/api/user/trade/log/store`,
          storeData,
          config
        )
        .then((data) => {
          if (data?.data?.success === true) {
            setResults(data?.data?.data);
            setCountDownModalShow(true);
            setHistoryDataUpdate(data?.data?.data);
            toast.success(`${data.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            refSubmitDisBuy.current.removeAttribute("disabled");
            refSubmitDisSell.current.removeAttribute("disabled");
          } else {
            toast.error(`${data.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            refSubmitDisBuy.current.removeAttribute("disabled");
            refSubmitDisSell.current.removeAttribute("disabled");
          }
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data?.errors);
          if (
            error?.response?.data?.success === false &&
            error?.response?.data?.message
          ) {
            toast.error(`${error?.response?.data?.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }

          refSubmitDisBuy.current.removeAttribute("disabled");
          refSubmitDisSell.current.removeAttribute("disabled");
        });
    } else {
      toast.error(`Something is wrong try again`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/auth/view/single/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
        });
    }
  }, [authUser?._id, results]);




  const [thradeSettingData, setthradeSettingData] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/admin/trade/setting/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setthradeSettingData(data?.data);
      });
  }, []);



  const date = new Date();
  const Saturday = dateFormat(date, "dddd");
  const Sunday = dateFormat(date, "dddd");

  return (
    <>
      <section className="user-trade-page">
        <div className="w-324 min-w-324 ml-10 mr-20 m-auto">
          <div className="px-20 py-10 bg-primary rounded-10 text-white text-center mb-18">
            <div className="text-14 py-6">UID : {authUser?.uid}</div>
            <div className="text-14">Available funds</div>
            <div className="text-lg font-bold">
              {!isNaN(userData?.balance) &&
                parseFloat(userData?.balance).toFixed(2)}
            </div>
          </div>
        </div>
        <div className="box-shadow rounded-10 p-20 box-border ">
          <div className="text-14">{marketSymbol}</div>
          <div className="text-xs text-kline-up">{livePrice}</div>
          <div className="el-select w-full mt-4">
            <div className="text-14 mt-10 trade-dis-btn">Time</div>
            <div id="timeSelection" class="time-selection mt-2">
            <div class="period-buttons">
              <div className="thradeSettingDataRadio">
                {thradeSettingData.map((data) => {
                  return (
                    <>
                      <input
                        type="radio"
                        name="thradeSetting_id"
                        id={data?._id}
                        value={data?._id}
                        key={data?._id}
                        onChange={(e)=>setTimeValue(e.target.value)}
                      />
                      <label htmlFor={data?._id}>
                        <FontAwesomeIcon icon={faClock} />
                        {data?.Time}S
                      </label>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
            {errorMessage?.thradeSetting_id && (
              <span className="text-danger pt-2">
                {errorMessage?.thradeSetting_id?.msg}
              </span>
            )}
          </div>
          <div className="el-select w-full my-4">
            <div className="text-14 mt-10 trade-dis-btn">
              Amount
            </div>
            <div className="trade-quantity mt-2">
              <input
                type="number"
                id="quantityInput"
                className="form-control "
                onChange={(e)=>setAmountValue(e.target.value)}
              />
            </div>
            {errorMessage?.amount && (
              <span className="text-danger pt-2">
                {errorMessage?.amount?.msg}
              </span>
            )}
          </div>
      
         
          <div>
            <div className="d-flex justify-content-between align-items-center pt-2">

            {Saturday === "Saturday" || Sunday === "Sunday" ? (
                <>
                  <button className="buy-btn" aria-disabled="false" disabled>
                    Buy
                  </button>
                  <button className="sell-btn" aria-disabled="false" disabled>
                    Sell
                  </button>
                </>
              ) : (
                <>
                 <button
                className="buy-btn"
                aria-disabled="false"
                type="submit"
                onClick={() => handleSubmitData("High")}
                ref={refSubmitDisBuy}
              >
                Long
              </button>
              <button
                className="sell-btn"
                aria-disabled="false"
                type="submit"
                onClick={() => handleSubmitData("Low")}
                ref={refSubmitDisSell}
              >
                Short
              </button>
                </>
              )}

          
            </div>
          </div>
        </div>
      </section>

      <TradeCountdown
      data={results}
      show={countDownModalShow}
      onHide={() => setCountDownModalShow(false)}
      setHistoryDataUpdate={setHistoryDataUpdate}
    ></TradeCountdown>

    </>
  );
};

export default ForexTrade;
