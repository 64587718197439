import React, { useEffect, useState } from "react";
import "./NewsPage.css";
import { faList, faGrip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "axios";
import CustomPagination from "../CustomPagination/CustomPagination";
import ListsSingleItem from "./ListsSingleItem";
import GridSingleItem from "./GridSingleItem";
const NewsPage = () => {
  const [NewsStyle, setNewsStyle] = useState("lists");

  const handeNewsItem = (news) => {
    switch (news) {
      case "lists":
        setNewsStyle("lists");
        break;

      case "grids":
        setNewsStyle("grids");
        break;

      default:
        break;
    }
  };

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/frontend/home/news/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API}/api/frontend/home/news/view/?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  if(loading){
    return;
  }
  return (
    <>
      <section className="news-section">
        <div className="news-section-title">
          <h5>News</h5>
        </div>
        <div className="container">
          <div className="main-news">
            <div className="news-area">
              <div className="news-tem">
                <p
                  onClick={() => handeNewsItem("lists")}
                  className={NewsStyle === "lists" ? "active" : ""}
                >
                  <FontAwesomeIcon icon={faList} /> Lists
                </p>
                <p
                  onClick={() => handeNewsItem("grids")}
                  className={NewsStyle === "grids" ? "active" : ""}
                >
                  <FontAwesomeIcon icon={faGrip} /> Girds
                </p>
              </div>

              {NewsStyle === "lists" && (
                <div className="news-lists-items">
                  {data?.length !== 0 ? (
                    data.map((data, index) => {
                      if (data) {
                        return (
                          <ListsSingleItem
                            data={data}
                            index={index}
                            key={data._id}
                          ></ListsSingleItem>
                        );
                      }
                    })
                  ) : (
                    <>
                      <div className="text-center h-50">
                        <h6> Data not found</h6>
                      </div>
                    </>
                  )}
                </div>
              )}

              {NewsStyle === "grids" && (
                <div className="news-grids-area">
                  <div className="row">
                  {data?.length !== 0 ? (
                    data.map((data, index) => {
                      if (data) {
                        return (
                          <GridSingleItem
                            data={data}
                            index={index}
                            key={data._id}
                          ></GridSingleItem>
                        );
                      }
                    })
                  ) : (
                    <>
                      <div className="text-center h-50">
                        <h6> Data not found</h6>
                      </div>
                    </>
                  )}
                  </div>
                </div>
              )}
            </div>
            {/* paginateLinks */}
            <CustomPagination
              data={paginateData}
              handlePage={handlePage}
            ></CustomPagination>
            {/* paginateLinks */}
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsPage;
