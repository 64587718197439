import React from "react";
import "./HultJoins.css";
import image from "./hppromo_billy_yahoofinance_openingbid.webp";
const HultJoins = () => {
  return (
    <>
      <div className="RTF">
        <section className="bg-color-gray-white-gradient py-4 py-lg-5">
          <div className="container mw-1200 mt-3 mb-3">
            <div className="row hp-featured-row-center">
              <div className="col-6">
                <a
                  href="#"
                  className="margin-content-image-left col-12 col-featured-image order-first bg-size-cover bg-size-cover-md figure mb-4 mb-lg-0 mnh-lg-315 js-sr-right px-sm-0 px-md-0 px-lg-0"
                  data-sr-id="21"
                >
                  <img
                    data-src={image}
                    className="content-promo-image"
                    alt="Billy Hult on Yahoo Finance"
                    src={image}
                  />
                </a>
              </div>
              <div className="col-6">
                <div
                  className="col-12 col-featured-content font-slatepro-light content-left js-sr-left"
                  data-sr-id="16"
                >
                  <div>
                    <div className="display-size-11">
                      <h4>
                        <span style={{ color: "#002d5b" }}>
                          Billy Hult Joins Opening Bid Podcast
                        </span>
                      </h4>
                      <p>
                        <span>Our CEO&nbsp;</span>
                        <a href="#">
                          Billy Hult
                        </a>
                        <span>&nbsp;recently joined the&nbsp;</span>
                        <em>Opening Bid</em>
                        <span>
                          &nbsp;podcast, hosted by Yahoo! Finance Executive
                          Editor Brian Sozzi, to discuss Tradeweb’s growth
                          trajectory, the future of
                        </span>
                        <a href="#">
                          <span>&nbsp;</span>automated trading,
                          <span>&nbsp;</span>
                        </a>
                        <span>
                          leadership lessons and the lasting influence of 9/11.
                        </span>
                      </p>
                    </div>
                    <footer className="mt-4">
                      <a
                        href="/newsroom/media-center/insights/video/billy-hult-joins-opening-bid-podcast/"
                        aria-label="Watch Billy Hult on Yahoo Finance"
                        className="btn btn-dark-blue"
                      >
                        <div className="d-flex align-items-center">
                          WATCH INTERVIEW
                        </div>
                      </a>
                    </footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default HultJoins;
