import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../Contexts/AuthContext/AuthProvider";
import SingleItem from "./SingleItem";
import axios from "axios";
import CustomPagination from "../../../CustomPagination/CustomPagination";

const History = ({HistoryDataUpdate}) => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/trade/log/history/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setPaginateData(data);
          setData(data?.data);
          setLoading(false);
        });
    }
  }, [authUser?._id, HistoryDataUpdate]);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API}/api/user/trade/log/history/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return;
  }
  return (
    <>
      <div className="trade-order-history-area">
        <table>
          <thead>
            <tr>
              <th>S.N.</th>
              <th>Pairs</th>
              <th>Amount</th>
              <th>High/Low</th>
              <th>Result</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.length !== 0 ? (
              data.map((data, index) => {
                if (data) {
                  return (
                    <SingleItem
                      data={data}
                      index={index}
                      key={data?._id}
                      paginateData={paginateData}
                    ></SingleItem>
                  );
                }
              })
            ) : (
              <tr>
                <td className="text-muted text-center" colSpan="100%">
                  No records available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* paginateLinks */}
        <CustomPagination
          data={paginateData}
          handlePage={handlePage}
        ></CustomPagination>
        {/* paginateLinks */}
      </div>
    </>
  );
};

export default History;
