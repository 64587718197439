import React from "react";
import "./News.css";
import { Link } from "react-router-dom";
import bg from "./news-insights-background1x.webp";
import image1 from "./mcthumb_izzy_igelectronification_520.webp";
import image2 from "./liz_iflr_mc-thumb.webp";
import image3 from "./mcthumb_nr_tw-ftse_wgbi_520px.webp";
const News = () => {
  return (
    <>
      <section
        className="pb-5 pt-5 bg-color-gray-white-gradient bg-size-cover related-news-block"
        style={{
          backgroundImage:
            `url(${bg})`,
        }}
      >
        <header className="container">
          <div className="bg-heading-rule d-none d-md-block mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="500"
              height="5"
              viewBox="0 0 500 5"
            >
              <g fill="none" fill-rule="evenodd">
                <rect
                  width="35"
                  height="5"
                  x="0"
                  fill="#00B6DE"
                  rx="2.5"
                  style={{
                    display: "inline-block",
                    width: "35px",
                    overflow: "hidden",
                  }}
                ></rect>
                <circle
                  cx="45"
                  cy="2.5"
                  r="2.5"
                  fill="#00B6DE"
                  className="animate-opacity-delay"
                ></circle>
                <circle
                  cx="57.5"
                  cy="2.5"
                  r="2.5"
                  fill="#00B6DE"
                  className="animate-opacity"
                ></circle>
                <rect
                  width="154"
                  height="5"
                  x="70"
                  fill="#00B6DE"
                  rx="2.5"
                  style={{
                    display: "inline-block",
                    width: "154px",
                    overflow: "hidden",
                  }}
                ></rect>
                <rect
                  width="90"
                  height="5"
                  x="234"
                  fill="#00B6DE"
                  rx="2.5"
                  style={{
                    display: "inline-block",
                    width: "90px",
                    overflow: "hidden",
                  }}
                ></rect>
                <circle
                  cx="296"
                  cy="2.5"
                  r="2.5"
                  fill="#002D5B"
                  className="animate-opacity-delay"
                ></circle>
                <rect
                  x="334"
                  width="146"
                  height="5"
                  fill="#00B6DE"
                  rx="2.5"
                  style={{
                    display: "inline-block",
                    width: "146px",
                    overflow: "hidden",
                  }}
                ></rect>
                <circle
                  cx="353"
                  cy="2.5"
                  r="2.5"
                  fill="#002D5B"
                  className="animate-opacity"
                ></circle>
                <circle
                  cx="490"
                  cy="2.5"
                  r="2.5"
                  fill="#002D5B"
                  className="animate-opacity-delay"
                ></circle>
              </g>
            </svg>
          </div>
          <div className="bg-heading-rule d-md-none mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="345"
              height="5"
              viewBox="0 0 345 5"
            >
              <g fill="none" fill-rule="evenodd">
                <rect
                  width="25"
                  height="5"
                  x="0"
                  fill="#00B6DE"
                  rx="2.5"
                  style={{ display: "inline-block", width: "25px" }}
                ></rect>
                <circle
                  cx="35"
                  cy="2.5"
                  r="2.5"
                  fill="#00B6DE"
                  className="animate-opacity-delay"
                ></circle>
                <circle
                  cx="47.5"
                  cy="2.5"
                  r="2.5"
                  fill="#00B6DE"
                  className="animate-opacity"
                ></circle>
                <rect
                  width="89"
                  height="5"
                  x="60"
                  fill="#00B6DE"
                  rx="2.5"
                  style={{ display: "inline-block", width: "89px" }}
                ></rect>
                <rect
                  width="50"
                  height="5"
                  x="159"
                  fill="#00B6DE"
                  rx="2.5"
                  style={{ display: "inline-block", width: "50px" }}
                ></rect>
                <circle
                  cx="195"
                  cy="2.5"
                  r="2.5"
                  fill="#002D5B"
                  className="animate-opacity-delay"
                ></circle>
                <rect
                  x="219"
                  width="106"
                  height="5"
                  fill="#00B6DE"
                  rx="2.5"
                  style={{ display: "inline-block", width: "106px" }}
                ></rect>
                <circle
                  cx="235"
                  cy="2.5"
                  r="2.5"
                  fill="#002D5B"
                  className="animate-opacity"
                ></circle>
                <circle
                  cx="335"
                  cy="2.5"
                  r="2.5"
                  fill="#002D5B"
                  className="animate-opacity-delay"
                ></circle>
              </g>
            </svg>
          </div>
          <h1
            className="display-size-2 display-size-2-sm font-untitled js-sr-up"
            data-sr-id="13"
          >
            <a
              className="color-marine-blue color-white no-underline"
              href="/newsroom/media-center/?Categories=5"
            >
              News &amp; Insights
            </a>
          </h1>
        </header>

        <div className="d-flex flex-column w-100 mb-0 mt-0 px-sm-0">
          <div className="container align-self-center d-none d-md-block mw-960">
            <div className="row relatednews color-white">
              <div className="col-12 col-md-4 mb-5 related-news-card">
                <div>
                  <div>
                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <div className="d-flex mb-4 color-white py-2 px-3 bg-color-dark-pink flex-wrap rounded-2xl">
                        <p className="display-size-13 display-size-13-sm border-right border-color-white mb-0 mr-2 pr-2 text-uppercase font-slatepro-bold color-white ls-09">
                          Blog
                        </p>
                        <p className="display-size-13 display-size-13-sm mb-0 font-slatepro color-white ls-03">
                          Credit
                        </p>
                      </div>
                    </a>

                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <div className="display-size-11 display-size-11-sm font-slatepro color-white">
                        Tradeweb Markets
                      </div>
                    </a>

                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <time
                        className="d-block mb-4 display-size-13 display-size-13-sm font-slatepro color-white"
                        pubdate=""
                        datetime="2024-10-16"
                      >
                        October 16, 2024
                      </time>
                    </a>
                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <figure className="figure m-0 p-0">
                        <img
                          data-src={image1}
                          className="img-fluid d-block mb-4"
                          alt="Izzy thumbnail "
                          src={image1}
                        />
                      </figure>
                    </a>
                    <Link
                      to="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <p className="title display-size-9 display-size-9-sm font-slatepro-medium color-white">
                        What the Last Decade of Electronification Tells Us About
                        the Future of U.S. Credit Markets
                      </p>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 mb-5 related-news-card">
                <div>
                  <div>
                    <Link
                      to="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <div className="d-flex mb-4 color-white py-2 px-3 bg-color-dark-pink flex-wrap rounded-2xl">
                        <p className="display-size-13 display-size-13-sm border-right border-color-white mb-0 mr-2 pr-2 text-uppercase font-slatepro-bold color-white ls-09">
                          In the News
                        </p>
                        <p className="display-size-13 display-size-13-sm mb-0 font-slatepro color-white ls-03">
                          Repo
                        </p>
                      </div>
                    </Link>

                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <div className="display-size-11 display-size-11-sm font-slatepro color-white">
                        IFLR
                      </div>
                    </a>

                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <time
                        className="d-block mb-4 display-size-13 display-size-13-sm font-slatepro color-white"
                        pubdate=""
                        datetime="2024-10-15"
                      >
                        October 15, 2024
                      </time>
                    </a>
                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <figure className="figure m-0 p-0">
                        <img
                          data-src={image2}
                          className="img-fluid d-block mb-4"
                          alt="Liz Kirby IFLR article"
                          src={image2}
                        />
                      </figure>
                    </a>
                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <p className="title display-size-9 display-size-9-sm font-slatepro-medium color-white">
                        Repo Market Needs Clearing Certainty
                      </p>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 mb-5 related-news-card">
                <div>
                  <div>
                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <div className="d-flex mb-4 color-white py-2 px-3 bg-color-dark-pink flex-wrap rounded-2xl">
                        <p className="display-size-13 display-size-13-sm border-right border-color-white mb-0 mr-2 pr-2 text-uppercase font-slatepro-bold color-white ls-09">
                          News Release
                        </p>
                        <p className="display-size-13 display-size-13-sm mb-0 font-slatepro color-white ls-03">
                          Data
                        </p>
                      </div>
                    </a>

                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <div className="display-size-11 display-size-11-sm font-slatepro color-white">
                        Tradeweb Markets
                      </div>
                    </a>

                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <time
                        className="d-block mb-4 display-size-13 display-size-13-sm font-slatepro color-white"
                        pubdate=""
                        datetime="2024-10-15"
                      >
                        October 15, 2024
                      </time>
                    </a>
                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <figure className="figure m-0 p-0">
                        <img
                          data-src={image3}
                          className="img-fluid d-block mb-4"
                          alt="WGBI to Incorporate Tradeweb FTSE Benchmark Closing Prices News Release"
                          src={image3}
                        />
                      </figure>
                    </a>
                    <a
                      href="#"
                      className="link d-block color-gray no-underline new-link"
                    >
                      <p className="title display-size-9 display-size-9-sm font-slatepro-medium color-white">
                        WGBI to Incorporate Tradeweb FTSE Benchmark Closing
                        Prices
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container d-block d-md-none">
            <div
              id="carouselNewsInsights-38bfffcc-72d9-443a-a8f0-78dc08998b2d"
              className="carousel slide carousel-news-insights"
            >
              <div className="carousel-inner relatednews color-white">
                <div className="carousel-item active" style={{ minHeight: "0px" }}>
                  <div className="related-news-card">
                    <div>
                      <div>
                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <div className="d-flex mb-4 color-white py-2 px-3 bg-color-dark-pink flex-wrap rounded-2xl">
                            <h2 className="display-size-13 display-size-13-sm border-right border-color-white mb-0 mr-2 pr-2 text-uppercase font-slatepro-bold color-white ls-09">
                              Blog
                            </h2>
                            <h3 className="display-size-13 display-size-13-sm mb-0 font-slatepro color-white ls-03">
                              Credit
                            </h3>
                          </div>
                        </a>

                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <div className="display-size-11 display-size-11-sm font-slatepro color-white">
                            Tradeweb Markets
                          </div>
                        </a>

                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <time
                            className="d-block mb-4 display-size-13 display-size-13-sm font-slatepro color-white"
                            pubdate=""
                            datetime="2024-10-16"
                          >
                            October 16, 2024
                          </time>
                        </a>
                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <figure className="figure m-0 p-0">
                            <img
                              data-src="/4a92b8/globalassets/newsroom/izzy-blog-oct-2024/mcthumb_izzy_igelectronification_520.jpg"
                              className="img-fluid d-block mb-4 lazy"
                              alt="Izzy thumbnail "
                            />
                          </figure>
                        </a>
                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <p className="title display-size-9 display-size-9-sm font-slatepro-medium color-white">
                            What the Last Decade of Electronification Tells Us
                            About the Future of U.S. Credit Markets
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item" style={{ minHeight: "0px" }}>
                  <div className="related-news-card">
                    <div>
                      <div>
                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <div className="d-flex mb-4 color-white py-2 px-3 bg-color-dark-pink flex-wrap rounded-2xl">
                            <h2 className="display-size-13 display-size-13-sm border-right border-color-white mb-0 mr-2 pr-2 text-uppercase font-slatepro-bold color-white ls-09">
                              In the News
                            </h2>
                            <h3 className="display-size-13 display-size-13-sm mb-0 font-slatepro color-white ls-03">
                              Repo
                            </h3>
                          </div>
                        </a>

                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <div className="display-size-11 display-size-11-sm font-slatepro color-white">
                            IFLR
                          </div>
                        </a>

                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <time
                            className="d-block mb-4 display-size-13 display-size-13-sm font-slatepro color-white"
                            pubdate=""
                            datetime="2024-10-15"
                          >
                            October 15, 2024
                          </time>
                        </a>
                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <figure className="figure m-0 p-0">
                            <img
                              data-src="/4a94b3/globalassets/newsroom/iflr-article-oct-2024/liz_iflr_mc-thumb.jpg"
                              className="img-fluid d-block mb-4 lazy"
                              alt="Liz Kirby IFLR article"
                            />
                          </figure>
                        </a>
                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <p className="title display-size-9 display-size-9-sm font-slatepro-medium color-white">
                            Repo Market Needs Clearing Certainty
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item" style={{ minHeight: "0px" }}>
                  <div className="related-news-card">
                    <div>
                      <div>
                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <div className="d-flex mb-4 color-white py-2 px-3 bg-color-dark-pink flex-wrap rounded-2xl">
                            <h2 className="display-size-13 display-size-13-sm border-right border-color-white mb-0 mr-2 pr-2 text-uppercase font-slatepro-bold color-white ls-09">
                              News Release
                            </h2>
                            <h3 className="display-size-13 display-size-13-sm mb-0 font-slatepro color-white ls-03">
                              Data
                            </h3>
                          </div>
                        </a>

                        <Link
                          to="#"
                          className="link d-block no-underline new-link"
                        >
                          <div className="display-size-11 display-size-11-sm font-slatepro color-white">
                            Tradeweb Markets
                          </div>
                        </Link>

                        <Link
                          to="#"
                          className="link d-block no-underline new-link"
                        >
                          <time
                            className="d-block mb-4 display-size-13 display-size-13-sm font-slatepro color-white"
                            pubdate=""
                            datetime="2024-10-15"
                          >
                            October 15, 2024
                          </time>
                        </Link>
                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <figure className="figure m-0 p-0">
                            <img
                              data-src="/4a95fc/globalassets/newsroom/data-news-release/mcthumb_nr_tw-ftse_wgbi_520px.jpg"
                              className="img-fluid d-block mb-4 lazy"
                              alt="WGBI to Incorporate Tradeweb FTSE Benchmark Closing Prices News Release"
                            />
                          </figure>
                        </a>
                        <a
                          href="#"
                          className="link d-block no-underline new-link"
                        >
                          <p className="title display-size-9 display-size-9-sm font-slatepro-medium color-white">
                            WGBI to Incorporate Tradeweb FTSE Benchmark Closing
                            Prices
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="container mt-3">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <a
                  href="#"
                  className="m-auto btn btn-transparent-white"
                >
                  <div className="d-flex align-items-center">
                    ALL NEWS &amp; INSIGHTS
                  </div>
                </a>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </>
  );
};

export default News;
