import React, { useContext } from "react";
import "./OffcanvasMenu.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
const OffcanvasMenu = ({handleClose}) => {
  const { authUser } = useContext(AuthContext);
  return (
    <>
      <section className="offcanvas-menu-section">
        <nav
          className="menu js-menu open"
          aria-label="Sidebar"
          aria-hidden="true"
        >
          <div className="container-fluid mt-lg-160 mega-menu-container">
            <div className="row">
              <div className="mega-menu mobile-search col-12 d-block d-lg-none">
                <form className="form-inline" method="POST" action="/search/">
                  <input
                    name="__RequestVerificationToken"
                    type="hidden"
                    value="MFe0iOKDNZ3F_tia0czXCCz3-XMy1gV_u98ZRMpH290gqmmj-bOeS6JLAcnmiLf3JA4--EA61IQcil9J15SK8y8dnS92ym4vMp82RCUHHP41"
                  />

                  <span className="nav-link search-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <g>
                          <rect
                            width="12"
                            height="3"
                            x="8.925"
                            y="13.425"
                            fill="#FFF"
                            rx="1.5"
                            transform="rotate(45 14.925 14.925)"
                          ></rect>
                          <circle
                            cx="7.5"
                            cy="7.5"
                            r="6"
                            stroke="#FFF"
                            stroke-width="3"
                          ></circle>
                        </g>
                      </g>
                    </svg>
                  </span>

                  <input
                    className="form-control js-search-box color-white font-slatepro ls-05"
                    name="keywords"
                    type="search"
                    placeholder="what can we help you find?"
                    aria-label="Search"
                  />

                  <button
                    className="search-arrow ml-auto js-search-submit"
                    onclick="event.target.parentNode.submit();"
                  >
                    <span className="sr-only">Submit</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="24"
                      viewBox="0 0 14 24"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <g stroke="#FFF" stroke-width="3">
                          <path d="M2 2L12 12 2 22"></path>
                        </g>
                      </g>
                    </svg>
                  </button>
                </form>
              </div>

              <div className="mega-menu col-12 col-lg-3">
                <ul
                  className="row mega-menu-tabs js-mega-menu"
                  role="menu"
                  aria-label="Primary"
                >
                  <li className="col-4 col-lg-12" role="none">
                    <button
                      className="btn btn-link p-0 d-block font-untitled display-size-7 display-size-8-sm ls-05 mega-menu-item menu3 mega-menu-item-active"
                      data-content="menu3"
                      data-background="/4a752b/globalassets/new-iop-website-art/menu-images/menu_who-we-are1x.jpg"
                    >
                      Who We Are
                      <br />
                      <span
                        className="ml-0 mt-3 go icons arrow d-flex align-items-center justify-content-center display-size-13"
                        aria-hidden="true"
                      >
                        Go
                      </span>
                    </button>
                  </li>
                  <li className="col-4 col-lg-12" role="none">
                    <button
                      className="btn btn-link p-0 d-block font-untitled display-size-7 display-size-8-sm ls-05 mega-menu-item menu1"
                      data-content="menu1"
                      data-background="/4a752b/globalassets/new-iop-website-art/menu-images/menu_our-markets1x.jpg"
                    >
                      Our Markets
                      <br />
                      <span
                        className="ml-0 mt-3 go icons arrow d-flex align-items-center justify-content-center display-size-13"
                        aria-hidden="true"
                      >
                        Go
                      </span>
                    </button>
                  </li>
                  <li className="col-4 col-lg-12" role="none">
                    <button
                      className="btn btn-link p-0 d-block font-untitled display-size-7 display-size-8-sm ls-05 mega-menu-item menu2"
                      data-content="menu2"
                      data-background="/4a752b/globalassets/new-iop-website-art/menu-images/menu_news-insights1x.jpg"
                    >
                      News &amp; Insights
                      <br />
                      <span
                        className="ml-0 mt-3 go icons arrow d-flex align-items-center justify-content-center display-size-13"
                        aria-hidden="true"
                      >
                        Go
                      </span>
                    </button>
                  </li>
                </ul>
              </div>

              <div className="mega-menu-content col-12 col-lg-9">
                <div id="menu3" className="row menu-board menu-board-active">
                  <div className="col-12 col-lg-3 mb-3 mt-4 mt-lg-0">
                    <ul
                      className="menu-board-list"
                      role="menu"
                      aria-label="Secondary"
                    >
                      <li role="none">
                        <h3 className="mb-4 mb-lg-3">
                          <a
                            className="menu-back col-xs-6"
                            href="#"
                            data-content="mainmenu1"
                            style={{display: "none"}}
                          >
                            <div className="back-arrow-mobile">‹</div>
                            Back
                          </a>
                          <a
                            className="menu-title col-xs-6 display-size-8 display-size-10-sm font-slatepro d-block lh-1_2"
                            role="menuitem"
                            href="/who-we-are/about-us/"
                            target=""
                            aria-label="About Us"
                            style={{marginLeft: "auto"}}
                          >
                            About Us
                          </a>
                        </h3>
                      </li>
                      <li className="mb-1" role="none">
                        <a
                          className="h5 font-slatepro"
                          href="/who-we-are/our-purpose/"
                          target=""
                          role="menuitem"
                          aria-label="Our Purpose"
                        >
                          Our Purpose
                        </a>
                      </li>
                      <li className="mb-1" role="none">
                        <a
                          className="h5 font-slatepro"
                          href="/who-we-are/leadership/"
                          target=""
                          role="menuitem"
                          aria-label="Leadership"
                        >
                          Leadership
                        </a>
                      </li>
                      <li className="mb-1" role="none">
                        <a
                          className="h5 font-slatepro"
                          href="/who-we-are/history/"
                          target=""
                          role="menuitem"
                          aria-label="History"
                        >
                          History
                        </a>
                      </li>
                      <li className="mb-1" role="none">
                        <a
                          className="h5 font-slatepro"
                          href="/who-we-are/locations/"
                          target=""
                          role="menuitem"
                          aria-label="Locations"
                        >
                          Locations
                        </a>
                      </li>
                      <li className="mb-4" role="none">
                        <a
                          className="h5 font-slatepro"
                          href="/who-we-are/awards/"
                          target=""
                          role="menuitem"
                          aria-label="Awards"
                        >
                          Awards
                        </a>
                      </li>
                      <li role="none">
                        <h3 className="mb-4 mb-lg-3">
                          <a
                            className="menu-back col-xs-6"
                            href="#"
                            data-content="mainmenu1"
                            style={{display: "none"}}
                          >
                            <div className="back-arrow-mobile">‹</div>
                            Back
                          </a>
                          <a
                            className="menu-title col-xs-6 display-size-8 display-size-10-sm font-slatepro d-block lh-1_2"
                            role="menuitem"
                            href="/who-we-are/tradeweb-corporate-responsibility/"
                            target=""
                            aria-label="Tradeweb Corporate Responsibility "
                            style={{marginLeft: "auto"}}
                          >
                            Tradeweb Corporate Responsibility
                          </a>
                        </h3>
                      </li>
                      <li className="mb-1" role="none">
                        <a
                          className="h5 font-slatepro"
                          href="/who-we-are/our-communities/"
                          target=""
                          role="menuitem"
                          aria-label="Our Communities"
                        >
                          Our Communities
                        </a>
                      </li>
                      <li className="mb-1" role="none">
                        <a
                          className="h5 font-slatepro"
                          href="/who-we-are/our-employees/"
                          target=""
                          role="menuitem"
                          aria-label="Our Employees"
                        >
                          Our Employees
                        </a>
                      </li>
                      <li className="mb-1" role="none">
                        <a
                          className="h5 font-slatepro"
                          href="/who-we-are/the-environment/"
                          target=""
                          role="menuitem"
                          aria-label="The Environment"
                        >
                          The Environment
                        </a>
                      </li>
                      <li className="mb-1" role="none">
                        <a
                          className="h5 font-slatepro"
                          href="/who-we-are/governance/"
                          target=""
                          role="menuitem"
                          aria-label="Governance"
                        >
                          Governance
                        </a>
                      </li>
                      <li className="mb-1">
                        <a
                          className="h5 font-slatepro"
                          href="https://cdn.tradeweb.com/sites/esg/index.html"
                          target="_blank"
                          role="menuitem"
                          aria-label="ESG Data &amp; Trading Insights"
                        >
                          ESG Data &amp; Trading Insights
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-lg-3 mb-3">
                    <ul
                      className="menu-board-list"
                      role="menu"
                      aria-label="Secondary"
                    >
                      <li role="none">
                        <h3 className="mb-4 mb-lg-3">
                          <a
                            className="menu-back col-xs-6"
                            href="#"
                            data-content="mainmenu1"
                            role="menuitem"
                            aria-label="Back"
                            style={{display: "none"}}
                          >
                            <div className="back-arrow-mobile">‹</div>
                            Back
                          </a>
                          {authUser?._id ?
                            <Link
                            className="menu-title col-xs-6 display-size-8 display-size-10-sm font-slatepro d-block lh-1_2"
                            role="menuitem"
                            to="/user/trade"
                            aria-label="Login"
                            style={{marginLeft: "auto"}}
                            onClick={handleClose}
                          >
                            Dashboard
                          </Link>
                          :
                          <Link
                          className="menu-title col-xs-6 display-size-8 display-size-10-sm font-slatepro d-block lh-1_2"
                          role="menuitem"
                          to="/login"
                          aria-label="Login"
                          style={{marginLeft: "auto"}}
                          onClick={handleClose}
                        >
                          Login
                        </Link>
                          }
                        
                        </h3>
                      </li>
                      <li role="none">
                        <h3 className="mb-4 mb-lg-3">
                          <a
                            className="menu-back col-xs-6"
                            href="#"
                            data-content="mainmenu1"
                            role="menuitem"
                            aria-label="Back"
                            style={{display: "none"}}
                          >
                            <div className="back-arrow-mobile">‹</div>
                            Back
                          </a>
                          <a
                            className="menu-title col-xs-6 display-size-8 display-size-10-sm font-slatepro d-block lh-1_2"
                            role="menuitem"
                            href="/who-we-are/careers/"
                            target=""
                            aria-label="Careers"
                            style={{marginLeft: "auto"}}
                          >
                            Careers
                          </a>
                        </h3>
                      </li>
                      <li role="none">
                        <h3 className="mb-4 mb-lg-3">
                          <a
                            className="menu-back col-xs-6"
                            href="#"
                            data-content="mainmenu1"
                            role="menuitem"
                            aria-label="Back"
                            style={{display: "none"}}
                          >
                            <div className="back-arrow-mobile">‹</div>
                            Back
                          </a>
                          <a
                            className="menu-title col-xs-6 display-size-8 display-size-10-sm font-slatepro d-block lh-1_2"
                            role="menuitem"
                            href="/who-we-are/trading-calendar/"
                            target=""
                            aria-label="Trading Calendar"
                            style={{marginLeft: "auto"}}
                          >
                            Trading Calendar
                          </a>
                        </h3>
                      </li>
                      <li role="none">
                        <h3 className="mb-4 mb-lg-3">
                          <a
                            className="menu-back col-xs-6"
                            href="#"
                            data-content="mainmenu1"
                            role="menuitem"
                            aria-label="Back"
                            style={{display: "none"}}
                          >
                            <div className="back-arrow-mobile">‹</div>
                            Back
                          </a>
                          <a
                            className="menu-title col-xs-6 display-size-8 display-size-10-sm font-slatepro d-block lh-1_2"
                            role="menuitem"
                            href="/who-we-are/economic-calendar/"
                            target=""
                            aria-label="Economic Calendar"
                            style={{marginLeft: "auto"}}
                          >
                            Economic Calendar
                          </a>
                        </h3>
                      </li>
                      <li role="none">
                        <h3 className="mb-4 mb-lg-3">
                          <a
                            className="menu-back col-xs-6"
                            href="#"
                            data-content="mainmenu1"
                            role="menuitem"
                            aria-label="Back"
                            style={{display: "none"}}
                          >
                            <div className="back-arrow-mobile">‹</div>
                            Back
                          </a>
                          <a
                            className="menu-title col-xs-6 display-size-8 display-size-10-sm font-slatepro d-block lh-1_2"
                            role="menuitem"
                            href="/who-we-are/contact-us/"
                            target=""
                            aria-label="Contact Us"
                            style={{marginLeft: "auto"}}
                          >
                            Contact Us
                          </a>
                        </h3>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-lg-3"></div>

                  <div className="col-12 col-lg-10 pb-5">
                    <header
                      className="d-flex mb-2 bg-color-dark-pink color-white py-2 px-3 mb-4"
                      style={{borderRadius: "16px"}}
                    >
                      <h2 className="display-size-13 display-size-12-sm mb-0 mr-2 pr-2 lh-1_2 ls-09 text-uppercase color-white font-slatepro-bold">
                        FEATURED AWARDS
                      </h2>
                    </header>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <p className="display-size-9 mb-2">
                          <a
                            href="/who-we-are/awards/"
                            className="color-white font-slatepro-medium"
                          >
                            Best Fixed Income Trading Platform
                          </a>
                        </p>
                        <p className="color-white font-slatepro ls-02 mb-4">
                          Financial News
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <p className="display-size-9 mb-2">
                          <a
                            href="/who-we-are/awards/"
                            className="color-white font-slatepro-medium"
                          >
                            OTC Trading Platform Innovation of the Year
                          </a>
                        </p>
                        <p className="color-white font-slatepro ls-02 mb-4">
                          Risk Magazine
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
              
            </div>
          </div>
        </nav>
      </section>
    </>
  );
};

export default OffcanvasMenu;
