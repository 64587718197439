import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer className="text-uppercase">
        <section className="bg-color-gray py-4 py-lg-3">
          <div className="container mw-1480">
            <div className="row">
              <div className="col-12 col-lg-9 align-self-center">
                <ul
                  className="nav display-size-08vw justify-content-center justify-content-lg-start mt-lg-3 font-slatepro"
                  aria-label="Footer Menu"
                >
                  <li className="nav-item">
                    <a
                      className="mx-2 mx-lg-0 mr-0 mr-lg-3 mb-2 d-block color-black-link no-underline pl-0"
                      href="/client-area/"
                    ></a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="mx-2 mx-lg-0 mr-0 mr-lg-3 mb-2 d-block color-black-link no-underline"
                      href="/disclosures/"
                    >
                      Disclosures
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="mx-2 mx-lg-0 mr-0 mr-lg-3 mb-2 d-block color-black-link no-underline"
                      href="https://investors.tradeweb.com/"
                      target="_blank"
                    >
                      Investor Relations
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="mx-2 mx-lg-0 mr-0 mr-lg-3 mb-2 d-block color-black-link no-underline"
                      href="/disclosures/japan-legal-disclosures/"
                    >
                      Japan Legal Notice
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="mx-2 mx-lg-0 mr-0 mr-lg-3 mb-2 d-block color-black-link no-underline"
                      href="/privacy-policy/"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="mx-2 mx-lg-0 mr-0 mr-lg-3 mb-2 d-block color-black-link no-underline"
                      href="/supply-chain-transparency/"
                    >
                      Supply Chain Transparency
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="mx-2 mx-lg-0 mr-0 mr-lg-3 mb-2 d-block color-black-link no-underline"
                      href="/terms-of-use/"
                    >
                      Terms of Use
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="mx-2 mx-lg-0 mr-0 mr-lg-3 mb-2 d-block color-black-link no-underline"
                      href="/vigilance-against-fraud/"
                    >
                      Vigilance Against Fraud
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-lg-3 align-self-center mt-3 mt-lg-0">
                <ul
                  className="nav justify-content-center justify-content-lg-end social-links"
                  aria-label="Social links"
                >
                  <li className="nav-item">
                    <a
                      className="ml-3"
                      href="https://twitter.com/tradeweb/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="25"
                        height="24"
                        viewBox="0,0,256,256"
                        style={{fill: "#000000"}}
                      >
                        <g
                          fill="#002d5b"
                          fill-rule="nonzero"
                          stroke="none"
                          stroke-width="1"
                          stroke-linecap="butt"
                          stroke-linejoin="miter"
                          stroke-miterlimit="10"
                          stroke-dasharray=""
                          stroke-dashoffset="0"
                          font-family="none"
                          font-weight="none"
                          font-size="none"
                          text-anchor="none"
                          style={{mixBlendMode: "normal"}}
                        >
                          <g transform="scale(8.53333,8.53333)">
                            <path d="M26.37,26l-8.795,-12.822l0.015,0.012l7.93,-9.19h-2.65l-6.46,7.48l-5.13,-7.48h-6.95l8.211,11.971l-0.001,-0.001l-8.66,10.03h2.65l7.182,-8.322l5.708,8.322zM10.23,6l12.34,18h-2.1l-12.35,-18z"></path>
                          </g>
                        </g>
                      </svg>
                      <span className="sr-only">Twitter</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="ml-3"
                      href="https://www.linkedin.com/company/tradeweb/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <g fill="none" fill-rule="evenodd">
                          <g>
                            <path
                              fill="#002D5B"
                              fill-rule="nonzero"
                              d="M4.12 20H.318V6.072h3.804V20zM2.22 4.467C.994 4.467 0 3.467 0 2.233 0 1 .994 0 2.219 0c1.225 0 2.219 1 2.219 2.233 0 1.234-.993 2.234-2.22 2.234zM19.336 20h-3.804v-7.095c0-4.265-5.072-3.942-5.072 0V20H6.657V6.072h3.803v2.235c1.77-3.274 8.876-3.516 8.876 3.135V20z"
                            ></path>
                          </g>
                        </g>
                      </svg>

                      <span className="sr-only">LinkedIn</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="ml-3"
                      href="https://www.instagram.com/tradeweb/?hl=en"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <g fill="none" fill-rule="evenodd">
                          <g>
                            <path
                              fill="#002D5B"
                              fill-rule="nonzero"
                              d="M10.002 4.872c-2.837 0-5.126 2.29-5.126 5.128s2.289 5.128 5.126 5.128c2.838 0 5.127-2.29 5.127-5.128s-2.29-5.128-5.127-5.128zm0 8.462c-1.834 0-3.333-1.495-3.333-3.334s1.495-3.334 3.333-3.334S13.335 8.161 13.335 10s-1.499 3.334-3.333 3.334zm6.532-8.671c0 .664-.535 1.196-1.195 1.196-.665 0-1.196-.536-1.196-1.196 0-.661.535-1.197 1.196-1.197.66 0 1.195.536 1.195 1.197zm3.396 1.213c-.076-1.602-.442-3.02-1.615-4.19C17.145.516 15.727.15 14.125.07c-1.65-.093-6.6-.093-8.25 0-1.597.076-3.016.442-4.19 1.611C.512 2.851.151 4.27.07 5.871c-.093 1.652-.093 6.601 0 8.253.076 1.602.442 3.02 1.615 4.19 1.174 1.17 2.588 1.535 4.19 1.616 1.65.093 6.6.093 8.25 0 1.602-.076 3.02-.442 4.19-1.616 1.169-1.17 1.534-2.588 1.615-4.19.093-1.652.093-6.596 0-8.248zm-2.133 10.02c-.348.874-1.022 1.548-1.9 1.9-1.317.523-4.44.402-5.895.402-1.454 0-4.582.116-5.894-.402-.874-.348-1.548-1.021-1.9-1.9-.523-1.317-.402-4.441-.402-5.896s-.116-4.583.401-5.895c.348-.875 1.022-1.549 1.901-1.901 1.316-.523 4.44-.402 5.894-.402 1.455 0 4.582-.116 5.894.402.875.348 1.549 1.021 1.901 1.9.522 1.317.402 4.441.402 5.896s.12 4.583-.402 5.895z"
                            ></path>
                          </g>
                        </g>
                      </svg>

                      <span className="sr-only">Instagram</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative">
          <div className="container mw-1480 py-4">
            <div className="row">
              <div className="col-12 display-size-14 display-size-14-sm text-lg-left color-black font-slatepro COPYRIGHT">
                COPYRIGHT © 2024 TRADEWEB MARKETS LLC. ALL RIGHTS RESERVED
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
