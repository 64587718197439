import React from "react";
import "./Progess.css";
import bgImage from "./parallax_homepage1x.webp";
const Progess = () => {
  return (
    <>
      <section
        class="bg-size-cover bg-attachment-fixed-lg pt-6 pb-6 js-stellar"
        style=  {{backgroundImage: `url(${bgImage})`}}
        data-stellar-background-ratio="0.5"
      >
        <div class="container d-none d-md-block mw-960 overflow-hidden">
          <div class="row justify-content-center flex-wrap">
            <div class="w-100 d-flex factbandblock color-white">
              <div class="col-6 col-lg-3">
                <p class="display-size-3 font-untitled-bold m-0 color-white">
                  25
                </p>
                <p class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                  YEARS OF LEADERSHIP IN ELECTRONIC TRADING
                </p>
              </div>
              <div class="col-6 col-lg-3">
                <p class="display-size-3 font-untitled-bold m-0 color-white">
                  2.7+
                </p>
                <p class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                  QUADRILLION USD TRADED SINCE LAUNCH
                </p>
                <p class="display-size-13 display-size-13-sm mt-2 mb-0">
                  (As of 2Q2024)
                </p>
              </div>
              <div class="col-6 col-lg-3">
                <p class="display-size-3 font-untitled-bold m-0 color-white">
                  50+
                </p>
                <p class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                  PRODUCTS OFFERED GLOBALLY
                </p>
              </div>
              <div class="col-6 col-lg-3">
                <p class="display-size-3 font-untitled-bold m-0 color-white">
                  12x
                </p>
                <p class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                  VOTED BEST FIXED INCOME PLATFORM
                </p>
                <p class="display-size-13 display-size-13-sm mt-2 mb-0">
                  by Financial News
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          class="container d-block d-md-none js-sr-up"
          data-sr-id="15"
         
        >
          <div class="slick-carousel row slick-initialized slick-slider">
            <button
              class="slick-prev slick-arrow"
              aria-label="Previous"
              type="button"
              
            >
              Previous
            </button>
            <div class="slick-list draggable">
              <div
                class="slick-track"
                
              >
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="-2"
                  aria-hidden="true"
                  style={{width: "0px"}}
                  tabindex="-1"
                >
                  <div>
                    <div
                      class="col-12 item factbandblock color-white"
                      style={{width: "100%", display: "inline-block"}}
                    >
                      <div class="d-block">
                        <h2 class="display-size-3 font-untitled-bold m-0 color-white">
                          50+
                        </h2>
                        <h6 class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                          PRODUCTS OFFERED GLOBALLY
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="-1"
                  aria-hidden="true"
                  style={{width: "0px"}}
                  tabindex="-1"
                >
                  <div>
                    <div
                      class="col-12 item factbandblock color-white"
                      style={{width: "100%", display: "inline-block"}}
                    >
                      <div class="d-block">
                        <h2 class="display-size-3 font-untitled-bold m-0 color-white">
                          12x
                        </h2>
                        <h6 class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                          VOTED BEST FIXED INCOME PLATFORM
                        </h6>
                        <p class="display-size-13 display-size-13-sm mt-2 mb-0">
                          by Financial News
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="slick-slide slick-current slick-active"
                  data-slick-index="0"
                  aria-hidden="false"
                  style={{width: "0px"}}
                >
                  <div>
                    <div
                      class="col-12 item factbandblock color-white"
                      style={{width: "100%", display: "inline-block"}}
                    >
                      <div class="d-block">
                        <h2 class="display-size-3 font-untitled-bold m-0 color-white">
                          25
                        </h2>
                        <h6 class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                          YEARS OF LEADERSHIP IN ELECTRONIC TRADING
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="slick-slide slick-active"
                  data-slick-index="1"
                  aria-hidden="false"
                  style={{width: "0px"}}
                >
                  <div>
                    <div
                      class="col-12 item factbandblock color-white"
                      style={{width: "100%", display: "inline-block"}}
                    >
                      <div class="d-block">
                        <h2 class="display-size-3 font-untitled-bold m-0 color-white">
                          2.7+
                        </h2>
                        <h6 class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                          QUADRILLION USD TRADED SINCE LAUNCH
                        </h6>
                        <p class="display-size-13 display-size-13-sm mt-2 mb-0">
                          (As of 2Q2024)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="slick-slide"
                  data-slick-index="2"
                  aria-hidden="true"
                  style={{width: "0px"}}
                  tabindex="-1"
                >
                  <div>
                    <div
                      class="col-12 item factbandblock color-white"
                      style={{width: "100%", display: "inline-block"}}
                    >
                      <div class="d-block">
                        <h2 class="display-size-3 font-untitled-bold m-0 color-white">
                          50+
                        </h2>
                        <h6 class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                          PRODUCTS OFFERED GLOBALLY
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="slick-slide"
                  data-slick-index="3"
                  aria-hidden="true"
                  style={{width: "0px"}}
                  tabindex="-1"
                >
                  <div>
                    <div
                      class="col-12 item factbandblock color-white"
                      style={{width: "100%", display: "inline-block"}}
                    >
                      <div class="d-block">
                        <h2 class="display-size-3 font-untitled-bold m-0 color-white">
                          12x
                        </h2>
                        <h6 class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                          VOTED BEST FIXED INCOME PLATFORM
                        </h6>
                        <p class="display-size-13 display-size-13-sm mt-2 mb-0">
                          by Financial News
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="4"
                  aria-hidden="true"
                  style={{width: "0px"}}
                  tabindex="-1"
                >
                  <div>
                    <div
                      class="col-12 item factbandblock color-white"
                      style={{width: "100%", display: "inline-block"}}
                    >
                      <div class="d-block">
                        <h2 class="display-size-3 font-untitled-bold m-0 color-white">
                          25
                        </h2>
                        <h6 class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                          YEARS OF LEADERSHIP IN ELECTRONIC TRADING
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="5"
                  aria-hidden="true"
                  style={{width: "0px"}}
                  tabindex="-1"
                >
                  <div>
                    <div
                      class="col-12 item factbandblock color-white"
                      style={{width: "100%", display: "inline-block"}}
                    >
                      <div class="d-block">
                        <h2 class="display-size-3 font-untitled-bold m-0 color-white">
                          2.7+
                        </h2>
                        <h6 class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                          QUADRILLION USD TRADED SINCE LAUNCH
                        </h6>
                        <p class="display-size-13 display-size-13-sm mt-2 mb-0">
                          (As of 2Q2024)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="6"
                  aria-hidden="true"
                  style={{width: "0px"}}
                  tabindex="-1"
                >
                  <div>
                    <div
                      class="col-12 item factbandblock color-white"
                      style={{width: "100%", display: "inline-block"}}
                    >
                      <div class="d-block">
                        <h2 class="display-size-3 font-untitled-bold m-0 color-white">
                          50+
                        </h2>
                        <h6 class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                          PRODUCTS OFFERED GLOBALLY
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="7"
                  aria-hidden="true"
                  style={{width: "0px"}}
                  tabindex="-1"
                >
                  <div>
                    <div
                      class="col-12 item factbandblock color-white"
                      style={{width: "100%", display: "inline-block"}}
                    >
                      <div class="d-block">
                        <h2 class="display-size-3 font-untitled-bold m-0 color-white">
                          12x
                        </h2>
                        <h6 class="display-size-13 display-size-13-sm font-slatepro-medium m-0 color-white">
                          VOTED BEST FIXED INCOME PLATFORM
                        </h6>
                        <p class="display-size-13 display-size-13-sm mt-2 mb-0">
                          by Financial News
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="slick-next slick-arrow"
              aria-label="Next"
              type="button"
            >
              Next
            </button>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Progess;
