import React, { useState, useEffect, useTransition, useRef } from "react";
import { createChart } from "lightweight-charts";
import { GetCandles, GetLiveCandle, GetAgre } from "./TradeLiveDataFun";
import dateFormat from "dateformat";
import MetalTrade from "./MetalTrade";
const MetalCandlestickCharts = ({ TradeData }) => {
  const [currentTimeFrame, updateTimeFrame] = useState({
    period: "1",
    period_type: "minute",
    limit: 1000,
    day: 2,
    activeclass: "1m",
  });
  const cryptoCoin = TradeData?.MarketData?.symbol;
  const [isPending, startTransition] = useTransition();
  const getLivePrice = useRef(null);
  const [LiveDataShow, setLiveDataShow] = useState();
  const chartContainerRef = useRef();
  var chart = useRef(null);
  var newSeries = useRef(null);

  // Admin update Price end

  const chartOptions = {
    layout: {
      textColor: "white",
      background: { type: "solid", color: "rgb(11, 6, 25)" },
    },
    grid: {
      vertLines: {
        color: "transparent",
      },
      horzLines: {
        color: "transparent",
      },
    },
    width: chartContainerRef.current && chartContainerRef.current.offsetWidth,
    height: 600,
  };

  useEffect(() => {
    chart.current = createChart(chartContainerRef.current, chartOptions);
    newSeries.current = chart.current.addCandlestickSeries({
      upColor: "#26a69a",
      downColor: "#ef5350",
      borderVisible: false,
      wickUpColor: "#26a69a",
      wickDownColor: "#ef5350",
    });

    // chart.current.timeScale().fitContent();
    chart.current.timeScale().scrollToPosition(5);

    chart.current.timeScale().applyOptions({
      rightOffset: 5,
      barSpacing: 10,
      minBarSpacing: 5,
      fixLeftEdge: true,
      borderVisible: false,
      timeVisible: true,
      tickMarkFormatter: (time, tickMarkType, locale) => {
        const date = new Date(parseFloat(time) * 1000);
        const timeFormatter = new Intl.DateTimeFormat(locale, {
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        });
        return timeFormatter.format(date);
      },
    });

    // Apply the custom priceFormatter to the chart
    chart.current.applyOptions({
      localization: {
        priceFormatter: (price) => price,
      },
    });

    return () => [chart.current.remove()];
  }, []);

  //update price chart
  useEffect(() => {
    const conn = new WebSocket(GetLiveCandle());
    //taken fron priceData.js
    startTransition(() => {
      conn.onopen = () => {
        conn.send(
          JSON.stringify({
            action: "auth",
            params: `${process.env.REACT_APP_POLYGON_API_KEY}`,
          })
        );
      };

      conn.onmessage = function (event) {
        const data = JSON.parse(event.data)[0];
        if (data?.status === "auth_success") {
          conn.send(
            JSON.stringify({
              action: "subscribe",
              params: `CAS.${cryptoCoin.substring(0, 3)}/${cryptoCoin.substring(
                3,
                6
              )}`,
            })
          );
        }

        if (data?.ev === "CAS") {
          GetAgre(currentTimeFrame, cryptoCoin).then((Resp) => {
            const resultsData = Resp?.data?.results;
            if (resultsData) {
              var liveData = data;
              var editLiveData = {
                time: resultsData[0].t / 1000,
                open: resultsData[0].o,
                high: parseFloat(liveData?.h),
                low: parseFloat(liveData?.l),
                close: parseFloat(liveData?.c),
              };
              getLivePrice.current = editLiveData.close;
              newSeries.current.update(editLiveData);
              setLiveDataShow(liveData);
            }
          });
        }
      };
      //taken fron priceData.js
      GetCandles(currentTimeFrame, cryptoCoin).then((Resp) => {
        const resultsData = Resp?.data?.results;
        const sort = resultsData.sort((a, b) => (a.t > b.t ? 1 : -1));
        const candles = sort.map((d) => ({
          time: d?.t / 1000,
          open: parseFloat(d?.o),
          high: parseFloat(d?.h),
          low: parseFloat(d?.l),
          close: parseFloat(d?.c),
        }));
        //As web scoket is delayed by 2 seconds, im setting live price to last candle open price
        getLivePrice.current = resultsData[resultsData.length - 1].c;
        setLiveDataShow(resultsData[resultsData.length - 1]);
        newSeries.current.setData(candles);
      });
    });

    return () => {
      conn.close();
    };
  }, [currentTimeFrame, cryptoCoin, cryptoCoin]);

  return (
    <>
      <div className="col-6">
        <div className="candle-time-framer">
          <p
            className={currentTimeFrame?.activeclass === "1m" ? "active" : ""}
            onClick={() =>
              updateTimeFrame({
                period: "1",
                period_type: "minute",
                limit: 1000,
                day: 2,
                activeclass: "1m",
              })
            }
          >
            1m
          </p>
          <p
            className={currentTimeFrame?.activeclass === "5m" ? "active" : ""}
            onClick={() =>
              updateTimeFrame({
                period: "5",
                period_type: "minute",
                limit: 10000,
                day: 2,
                activeclass: "5m",
              })
            }
          >
            5m
          </p>
          <p
            className={currentTimeFrame?.activeclass === "15m" ? "active" : ""}
            onClick={() =>
              updateTimeFrame({
                period: "15",
                period_type: "minute",
                limit: 10000,
                day: 10,
                activeclass: "15m",
              })
            }
          >
            15m
          </p>
          <p
            className={currentTimeFrame?.activeclass === "30m" ? "active" : ""}
            onClick={() =>
              updateTimeFrame({
                period: "30",
                period_type: "minute",
                limit: 50000,
                day: 30,
                activeclass: "30m",
              })
            }
          >
            30m
          </p>
          <p
            className={currentTimeFrame?.activeclass === "1h" ? "active" : ""}
            onClick={() =>
              updateTimeFrame({
                period: "1",
                period_type: "hour",
                limit: 50000,
                day: 365,
                activeclass: "1h",
              })
            }
          >
            1h
          </p>
          <p
            className={currentTimeFrame?.activeclass === "1d" ? "active" : ""}
            onClick={() =>
              updateTimeFrame({
                period: "1",
                period_type: "day",
                limit: 50000,
                day: 365,
                activeclass: "1d",
              })
            }
          >
            1d
          </p>
        </div>
        <div className="user-live-data-show">
          <div className="user-live-data-symbol">
            <h5>{cryptoCoin}</h5>
          </div>
          <div className="user-live-data">
            <p>
              Time:
              {LiveDataShow?.s
                ? dateFormat(parseFloat(LiveDataShow?.s), "yyyy-mm-dd h:MM TT")
                : LiveDataShow?.t &&
                  dateFormat(parseFloat(LiveDataShow?.t), "yyyy-mm-dd h:MM TT")}
            </p>
            <p>
              Open:
              {LiveDataShow?.o && parseFloat(parseFloat(LiveDataShow?.o))}
            </p>
            <p>
              High:
              {LiveDataShow?.h && parseFloat(parseFloat(LiveDataShow?.h))}
            </p>
            <p>
              Low:
              {LiveDataShow?.l && parseFloat(parseFloat(LiveDataShow?.l))}
            </p>
            <p>
              Close:
              {LiveDataShow?.c && parseFloat(parseFloat(LiveDataShow?.c))}
            </p>
            <p>
              Volume:
              {LiveDataShow?.v && parseFloat(LiveDataShow?.v)}
            </p>
          </div>
        </div>
        <div
          ref={chartContainerRef}
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
      </div>
      <div className="col-3">
        <MetalTrade TradeData={TradeData} getLivePrice={getLivePrice} />
      </div>
    </>
  );
};

export default MetalCandlestickCharts;
