import React from "react";
import "./ClientsAndMarkets.css";
const ClientsAndMarkets = () => {
  return (
    <>
      <div className="RTF ClientsAndMarkets">
        &#xFEFF;
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-12 display-size-11">
                <h3>
                  <span style={{ color: "#002d5b" }}>
                    Our Clients and Markets
                  </span>
                </h3>
                <p>
                  Tradeweb has been raising the bar for electronic trading for
                  over two decades.
                  &nbsp; <a href="#">Institutional</a>,&nbsp;
                  &nbsp; <a href="#">wholesale</a>,&nbsp;
                  &nbsp; <a href="#">
                    retail
                  </a>
                  and
                  &nbsp; <a href="#">
                    corporate treasury traders
                  </a>
                  &nbsp; rely on our deep liquidity pools, industry-defining trading
                  technology and best-in-className client service to trade
                  confidently across
                  &nbsp; <a href="#">rates</a>,&nbsp;
                  <a href="#">credit</a>,&nbsp;
                  <a href="#">equities</a>&nbsp;
                  &nbsp; and
                  &nbsp;  <a href="#">
                    money markets
                  </a>
                  .
                </p>
                <p>
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ClientsAndMarkets;
