import React, { useState, useEffect, useTransition, useRef } from "react";
import { createChart } from "lightweight-charts";
import { GetCandles, GetLiveCandle } from "./TradeLiveDataFun";
import dateFormat from "dateformat";
const StockCandlestickCharts = ({ TradeData }) => {
  const updateAdminPrice = TradeData?.MarketData?.price;
  const [currentTimeFrame, updateTimeFrame] = useState("1");
  const cryptoCoin = `X:BTCUSD`; ///// crypto
  // const cryptoCoin = `C:XAUUSD`; //// gold 
  const [isPending, startTransition] = useTransition();
  const getLivePrice = useRef(null);
  const [livePrice, setLivePrice] = useState();
  const [LiveDataShow, setLiveDataShow] = useState();
  const chartContainerRef = useRef();
  var chart = useRef(null);
  var newSeries = useRef(null);

  const chartOptions = {
    layout: {
      textColor: "white",
      background: { type: "solid", color: "rgb(11, 6, 25)" },
    },
    grid: {
      vertLines: {
        color: "transparent",
      },
      horzLines: {
        color: "transparent",
      },
    },
    width: chartContainerRef.current && chartContainerRef.current.offsetWidth,
    height: 600,
  };

  useEffect(() => {
    chart.current = createChart(chartContainerRef.current, chartOptions);
    newSeries.current = chart.current.addCandlestickSeries({
      upColor: "#26a69a",
      downColor: "#ef5350",
      borderVisible: false,
      wickUpColor: "#26a69a",
      wickDownColor: "#ef5350",
    });

    // chart.current.timeScale().fitContent();
    chart.current.timeScale().scrollToPosition(5);
    chart.current.timeScale().applyOptions({
      rightOffset: 5,
      barSpacing: 15,
      minBarSpacing: 5,
      fixLeftEdge: true,
      borderVisible: false,
      timeVisible: true,
      fixRightEdge: true,
      tickMarkFormatter: (time, tickMarkType, locale) => {
        const date = new Date(parseFloat(time) * 1000);
        const timeFormatter = new Intl.DateTimeFormat(locale, {
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        });
        return timeFormatter.format(date);
      },
    });

    return () => [chart.current.remove()];
  }, []);

  //update price chart
  useEffect(() => {
    const conn = new WebSocket(GetLiveCandle(currentTimeFrame, cryptoCoin));
    startTransition(() => {
      //taken fron priceData.js
      GetCandles(currentTimeFrame, cryptoCoin).then((Resp) => {
        const resultsData = Resp?.data?.results;
        const candles = resultsData.map((d) => ({
          time: d?.t / 1000,
          open: parseFloat(d?.o),
          high: parseFloat(d?.h),
          low: parseFloat(d?.l),
          close: parseFloat(d?.c),
        }));
        // As web scoket is delayed by 2 seconds, im setting live price to last candle open price
        const candleLen = candles.length;
        getLivePrice.current = candles[candleLen - 1].open;
        newSeries.current.setData(candles);
      });
    });

    return () => {
      conn.close();
    };
  }, [currentTimeFrame, cryptoCoin]);

  return (
    <>
      <div className="col-6">
        <div
          ref={chartContainerRef}
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
      </div>
    </>
  );
};

export default StockCandlestickCharts;
