import React from "react";
import "./About.css";
import share from "./aboutus1.png";
import hand from "./aboutus1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSketch,
} from "@fortawesome/free-brands-svg-icons";
import { 
  faGlobe,
  faShieldHalved,
  faShieldHeart,
  faCalendarDays,
  faArrowUpRightDots,
  faMobileScreen,
  faCoins,
  faXmark,
  faMoneyBillWave,
  faMasksTheater,
  faExplosion
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import graphic from "./graphic.png"
const About = () => {
  return (
    <>
      <section className="about-section">
        <div className="about-title">
          <h4 data-aos="fade-down" data-aos-duration="2000">
            About Trade Web
          </h4>
          <p data-aos="fade-down" data-aos-duration="2000">
            The biggest and best Forex broker that you can trust.
          </p>
        </div>

        <div className="container">
          <div className="about-main">
            <div
              className="about-first-section"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <p>
                Trade Web Limited is a leading international
                provider of online forex trading, precious metals,Oil, Stock and
                Cryptocurrency contracts for difference, and related services.
                The management team is made up of experienced industry
                professionals dedicated to providing investors with
                industry-leading services.
              </p>
            </div>
            <div className="about-first-section-image">
              <div className="row">
                <div className="col-6">
                  <p data-aos="fade-up" data-aos-duration="3000">
                    The company seeks to provide innovative trading tools,
                    adhere to strict financial regulations, and endeavor to give
                    the finest online trading experience on the market.
                  </p>
                </div>
                <div className="col-6">
                  <img
                    src={hand}
                    alt=""
                    data-aos="zoom-in-up"
                    data-aos-duration="2000"
                  />
                </div>
              </div>
              <span data-aos="fade-up" data-aos-duration="3000">
                RUBICON LIBERTY is a centralized and decentralized blockchain
                exchange built for traders. In RUBICON LIBERTY, both novices and
                veterans can trade with confidence and at will, conveniently and
                quickly. RUBICON LIBERTY provides innovative products, including
                derivatives, options, crypto gold, stock and Forex volatility
                products and leveraged tokens and spot. RUBICON LIBERTY takes
                safe investment as its mission and is committed to providing
                industry-leading security and stability. We strive to develop a
                platform that is powerful enough for professional trading firms
                and intuitive enough for first-time users. RUBICON LIBERTY uses
                a flexible, multi-stage wallet strategy to ensure that all funds
                are kept in cold storage for enhanced security. Additionally
                RUBICON LIBERTY enables two-factor authentication for all users
                and provides many additional security features to provide
                multiple layers of protection. RUBICON LIBERTY has tens of
                millions of users (with an advance compensation mechanism to
                protect investors’ capital security). At RUBICON LIBERTY safety
                is always at the forefront of every decision we make. RUBICON
                LIBERTY uses the most reliable and effective security
                technology. RUBICON LIBERTY currently has users in more than 100
                countries and regions around the world. With 1.4 million
                orders/second core memory matching technology, it is one of the
                fastest options trading platforms in the world and one of the
                platforms with the largest options trading volume in the world.
                Our team provides 24/7 multilingual customer service support in
                multiple countries and regions throughout the year. Our mission
                is to provide infrastructure services for the entire blockchain
                ecosystem, help more and more people experience financial
                status, and help more and more people achieve financial
                security, financial mobility and financial freedom. Reduce
                environmental pollution and global energy consumption, and
                protect the earth's nature, environment, and climate.
              </span>
            </div>
          </div>
        </div>
        <section className="our-target ">
          <div className="about style-2 bg-1 ">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="about__content"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div className="row ">
                      <div className="col-xl-5 col-md-12">
                        <div className="block-text">
                          <h3 className="title">Our Target </h3>
                          <p className="fs-20 mt-12">
                            Our target is to provide innovative trading tools,
                            meet stringent financial standards and strive to
                            provide the best online trading experience in the
                            market.
                          </p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-md-12">
                        <div className="tf-box">
                          <div className="box-icon">
                            <span className="icon-buy">
                            <FontAwesomeIcon icon={faMoneyBillWave} />
                            </span>
                            <p>Buy and sell items easily</p>
                          </div>
                          <div className="box-icon">
                            <span className="icon-create">
                              <FontAwesomeIcon icon={faMasksTheater} />
                            </span>
                            <p>Create collections &amp; earn rewards</p>
                          </div>
                          <div className="box-icon">
                            <span className="icon-chase">
                              <FontAwesomeIcon icon={faExplosion} />
                            </span>
                            <p>Chase limited edition V-FTC</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="share-market-section">
            <div className="row">
              <div className="col-6">
                <img
                  src={share}
                  alt=""
                  data-aos="zoom-in-up"
                  data-aos-duration="2000"
                />
              </div>
              <div
                className="col-6"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h5>
                  This website uses professional-grade encryption for all
                  transactions.
                </h5>
                <p>
                  Traders at Trade Web Limited don't have to worry
                  about transaction security; they can concentrate just on
                  transactions. High-level security procedures have been
                  implemented by Trade Web Limited to guarantee that
                  client transactions are always conducted in a secure setting.
                  We at Global Market offer our clients the best possible
                  trading experience.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section className="what-is-rubicon-section ">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-md-12">
                <div className="about__image">
                  <img
                    src={graphic}
                    alt=""
                    data-aos="zoom-in-up"
                    data-aos-duration="2000"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-12">
                <div className="about__content">
                  <div className="block-text">
                    <h3
                      className="title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      What is Trade Web Limited?
                    </h3>
                    <p data-aos="fade-up" data-aos-duration="800">
                      The world's first platform to reward investors and traders
                      who generate trade commissions in nodes like Forex,
                      Metals, Oil, Stocks and Digitalcurrency
                    </p>
                  </div>

                  <div
                    className="list-icon-box aos-init"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <span className="icon-internet">
                          <FontAwesomeIcon icon={faGlobe} />
                        </span>
                      </div>
                      <div className="content">
                        <h5>Decentralized platform</h5>
                        <p className="fs-14">
                          An all-in-one investment platform that allows
                          customers to invest in a variety of asset classNamees
                          including digital currencies, gold, stocks and forex.
                          It caters to diverse investment needs, providing
                          diverse opportunities to grow and manage wealth.
                        </p>
                      </div>
                    </div>
                    <div className="icon-box">
                      <div className="icon">
                        <span className="icon-users">
                          <FontAwesomeIcon icon={faSketch} />
                        </span>
                      </div>
                      <div className="content">
                        <h5>
                          Modern technology combined with a user- friendly
                          interface
                        </h5>
                        <p className="fs-14">
                          With Modern technology and a user-friendly interface,
                          Trade Web Limited provides
                          state-of-the-art trading tools, real-time data, and
                          powerful analytics to help investors make informed
                          decisions. The platform is designed to be accessible
                          and efficient for both new and experienced traders.
                        </p>
                      </div>
                    </div>
                    <div className="icon-box">
                      <div className="icon">
                        <span className="icon-users">
                          <FontAwesomeIcon icon={faShieldHalved} />
                        </span>
                      </div>
                      <div className="content">
                        <h5>Security and support</h5>
                        <p className="fs-14">
                          Trade Web Limited places a high priority
                          on security and openness, utilizing strong security
                          protocols and transparent, competitive pricing
                          schemes. In addition to a wealth of instructional
                          materials and helpful customer service. A secure and
                          encouraging atmosphere is guaranteed for managing
                          investments by Trade Web Limited.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="business-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="block-text center">
                  <h3
                    className="title"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    Business deal with Trade Web Limited ?
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div
                  className="choose__box"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div className="choose__box-icon center">
                    <div className="icon">
                      <span className="icon-vuesax">
                        <FontAwesomeIcon icon={faShieldHeart} />
                      </span>
                    </div>
                    <div className="content">
                      <Link to="#">Trading with digital currencies</Link>
                      <p>
                        Trade Web Limited provides customers with
                        short and long-term trading of digital currencies for
                        standard profit margin.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="choose__box"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div className="choose__box-icon center">
                    <div className="icon">
                      <span className="icon-startup">
                        <FontAwesomeIcon icon={faArrowUpRightDots} />
                      </span>
                    </div>
                    <div className="content">
                      <Link to="#">Digital currency mining </Link>
                      <p>
                        We make it easy for investors to purchase and invest in
                        the mining sector.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="choose__box"  data-aos="fade-up"
                  data-aos-duration="3000">
                  <div className="choose__box-icon center">
                    <div className="icon">
                      <span className="icon-calendar">
                        <FontAwesomeIcon icon={faCalendarDays} />
                      </span>
                    </div>
                    <div className="content">
                      <Link to="#">Minerals Trading</Link>
                      <p>
                        Trade Web Limited has trading in the most
                        popular minerals such as gold, silver, crude oil,
                        natural gas.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="choose__box"  data-aos="fade-up"
                  data-aos-duration="3000">
                  <div className="choose__box-icon center">
                    <div className="icon">
                      <span className="icon-mobile">
                        <FontAwesomeIcon icon={faMobileScreen} />
                      </span>
                    </div>
                    <div className="content">
                      <Link to="#">Stock investment</Link>
                      <p>
                        We have the most popular stock investments like Tesla,
                        Apple, Amazon, Google, Meta, Nevada. The system is
                        conveniently nice, but offers a bonus for individuals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="choose__box"  data-aos="fade-up"
                  data-aos-duration="3000">
                  <div className="choose__box-icon center">
                    <div className="icon">
                      <span className="icon-card">
                        <FontAwesomeIcon icon={faCoins} />
                      </span>
                    </div>
                    <div className="content">
                      <Link to="#">Forex Trading</Link>
                      <p>
                        We give our investors with safe and secure forex trading
                        as well as market analyst advise.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="restricted-area-section ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div
                  className="restricted-area-title"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <FontAwesomeIcon icon={faXmark} />
                  <h5>Restricted Area</h5>
                  <p>
                    Rubicon and its Services are not authorized by individuals
                    or entities that reside, are incorporated, or have another
                    registered office here. Y defined as below. Rubicon makes no
                    exception; Any such attempt to use our services from a
                    restricted region is strictly prohibited
                  </p>
                  <p>
                    Any other country or territory subject to comprehensive US
                    sanctions If you are a citizen, resident or entity of any
                    Restricted Territory, you are prohibited from using the
                    Rubicon Services Rubicon reserves the right to block access
                    to its Services from IP addresses or devices belonging to
                    restricted areas
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div
                  className="restricted-area-content"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <h5>
                    Rubicon restricts access to its Services from the following
                    jurisdictions (“Restricted Territories”):
                  </h5>
                  <div className="d-flex">
                    <ol className="restricted-area-list">
                      <li tabIndex="1">North Korea</li>
                      <li tabIndex="2">Israel</li>
                      <li tabIndex="1">China</li>
                    </ol>
                    <ol className="restricted-area-list">
                      <li tabIndex="1">Vanuatu</li>
                      <li tabIndex="1">cuba</li>
                      <li tabIndex="1">Iran</li>
                    </ol>
                    <ol className="restricted-area-list">
                      <li tabIndex="1">North Korea</li>
                      <li tabIndex="1">Syria</li>
                    </ol>
                  </div>
                  <p>
                    By accessing or using Rubicon, you represent and warrant
                    that you are not a citizen or resident of, in or under the
                    control of, any such restricted territory.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default About;
