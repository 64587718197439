import React from "react";
import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <>
      <section className="about-section">
        <div className="about-title">
          <h4 data-aos="fade-down" data-aos-duration="2000">Privacy Policy</h4>
        </div>

        <div className="container">
          <div className="benefits-content-area" data-aos="fade-up"
                  data-aos-duration="3000">
            <ul>
              <li>
                This privacy policy is applicable to our website, web
                applications, mobile applications, iOS applications, smart
                contracts, APIs, and any other software that we create or use.
                It applies to all information we obtain from you through it. By
                accessing our website, you accept the terms of this privacy
                policy. How we use your personal information.
              </li>
              <li>
                Trade Web Limited is committed to ensuring the
                privacy and security of all users' personal information. This
                Privacy Policy defines our practices for collecting, using, and
                disclosing personal information when you use our Services.
              </li>
            </ul>
          </div>

          <div className="forex-key-features" data-aos="fade-up"
                  data-aos-duration="3000">
            <h5>The kinds of data we collect from one of our customers</h5>

            <p>
              <span>Personal information such as: </span>
              Registration Data: When you make an account, we collect personal
              information such as your name, email address, phone number, and
              other contact information.
            </p>

            <p>
              <span>Verification Information:: </span>
              To comply with regulatory requirements, we might require
              additional information such as your government-issued
              identification, date of birth, and social security number.
            </p>

            <p>
              <span>Financial information:: </span>
              Payment Information: We gather payment information such as bank
              account information, credit card numbers, and transaction history
              in order to process your investments and withdrawals.
            </p>
          </div>

          <div className="forex-key-features" data-aos="fade-up"
                  data-aos-duration="3000">
            <h5>Log data</h5>
            <p>
              Our servers automatically record information when you access our
              Services, including the pages you visit, the time and date of your
              visit, and the time spent on those pages.
            </p>
            <p>
              <span>How we use your information: </span>
              To provide and improve our services <br />
              We use your personal and financial information to facilitate your
              investment, process transactions and provide customer support. We
              analyze usage data to improve the functionality and performance of
              our platform.
            </p>
            <p>
              <span>Compliance with Legal Obligations: </span>
              We use your information to comply with relevant laws, regulations,
              and industry standards, such as anti-money laundering (AML) and
              know-your-customer (KYC) requirements.
            </p>
            <p>
              <span>with legal and regulatory authorities: </span>
              We may share your information to regulatory and law enforcement
              authorities as required by law or if we feel it is necessary to
              protect our rights, property, or users.
            </p>
          </div>

          <div className="benefits-gold-investment-services-area" data-aos="fade-up"
                  data-aos-duration="3000">
            <div className="benefits-gold-investment-services">
              <h5>Data Security</h5>
              <div className="benefits-gold-investment-services-list custom-lists ">
                <ol>
                  <li>
                    Security system
                    <p>
                      We utilize robust security measures, such as encryption,
                      firewalls, and Secure Sockets Layer (SSL) technology, to
                      safeguard your personal and financial information from
                      unauthorized access, disclosure, or misuse.
                    </p>
                  </li>
                  <li>
                    Access control
                    <p>
                      Access to your personal information is restricted to
                      authorized staff who require it to carry out their job
                      activities.
                    </p>
                  </li>
                  <li>
                    Data retention
                    <p>
                      We store your personal information for as long as
                      necessary to carry out the purposes outlined in this
                      Privacy Policy, unless a longer retention period is needed
                      or permitted by law.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="benefits-gold-investment-services-area" data-aos="fade-up"
                  data-aos-duration="3000">
            <div className="benefits-gold-investment-services">
              <h5>Your rights and choices </h5>
              <div className="benefits-gold-investment-services-list custom-lists ">
                <ol>
                  <li>
                    Access and correction.
                    <p>
                      You have the right to know and update your personal
                      information. You can change your information in your
                      account settings or by contacting our customer service
                      team.
                    </p>
                  </li>
                  <li>
                    deleted
                    <p>
                      You have the right to request that your personal
                      information be erased, subject to certain legal and
                      regulatory limitations.
                    </p>
                  </li>
                  <li>
                    Changes to the Privacy Policy
                    <p>
                      We may update this Privacy Policy to reflect changes in
                      our practices or legal obligations. We will notify you of
                      any major changes by posting the amended Policy on our
                      Platform and, where applicable, sending you an email
                      notice.
                    </p>
                  </li>
                  <li>
                    Contact Us
                    <p>
                      If you have any questions or issues about this Privacy
                      Policy or our data practices, please contact us.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
