import React from "react";
import TopBannar from "./TopBannar/TopBannar";
import ClientsAndMarkets from "./ClientsAndMarkets/ClientsAndMarkets";
import Progess from "./Progess/Progess";
import Incorporate from "./Incorporate/Incorporate";
import HultJoins from "./HultJoins/HultJoins";
import OffTheDesk from "./OffTheDesk/OffTheDesk";
import SystematicTrading from "./SystematicTrading/SystematicTrading";
import News from "./News/News.js";
import Footer from "../Layouts/Footer/Footer.js";

const Home = () => {
  return (
    <>
      <TopBannar />
      <ClientsAndMarkets />
      <Progess />
      <Incorporate />
      <HultJoins />
      <OffTheDesk />
      <SystematicTrading />
      <News />
      <Footer /> 

    </>
  );
};

export default Home;
