import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import dateFormat from "dateformat";
const SingleItem = ({ data, handleTradeChart }) => {
  // const TotalLivePrice = parseFloat(livePrice[index]?.mid) + parseFloat(data?.price);

  const [SingleData, setSingleData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data?.metals_id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/trade/market/metals/view/${data?.metals_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data.data);
          setLoading(false);
        });
    }
  }, [data?.metals_id]);

  const date = new Date();
  const Saturday = dateFormat(date, "dddd");
  const Sunday = dateFormat(date, "dddd");

  if (loading) {
    return;
  }

  return (
    <>
      <div
        className="flex py-14 cursor-pointer items-center hover:bg-gray-100 px-10 bottom-border"
        onClick={() =>
          handleTradeChart({
            MarketData: SingleData,
            marketSymbol: SingleData?.symbol,
            Market: "Metal",
          })
        }
      >
        <div className="flex-1 flex items-center">
          <LazyLoadImage
            alt="img"
            effect="blur"
            className="w-36 h-36"
            src={`${process.env.REACT_APP_API}/${SingleData?.image}`}
          />

          <span className="pl-10">{SingleData?.symbol}</span>
        </div>
        {Saturday === "Saturday" || Sunday === "Sunday" ? (
          <>
            <h6 className="closed-market-btn">Closed</h6>
          </>
        ) : (
          ""
        )}
        {/* <div className="flex-1 text-kline-up">{ !(isNaN(TotalLivePrice)) && TotalLivePrice}</div> */}
      </div>
    </>
  );
};

export default SingleItem;
