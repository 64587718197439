import React from "react";
import image from "./hppromo_podcastannouncement_1728x1000.jpg";
const OffTheDesk = () => {
  return (
    <>
      <div className="RTF">
        <section className="bg-color-gray-white-gradient py-4 py-lg-5">
          <div className="container mw-1200 mt-3 mb-3">
            <div className="row hp-featured-row-center">
              <div className="col-6">
                <div
                  className="col-12 col-featured-content font-slatepro-light js-sr-right"
                  data-sr-id="22"
                >
                  <div>
                    <div className="display-size-11">
                      <h4>
                        <span style={{ color: "#002d5b" }}>
                          Off the Desk On Tradeweb: The Podcast
                        </span>
                      </h4>
                      <p>
                        Our new podcast, <em>Off the Desk On Tradeweb</em>, is a
                        series of engaging discussions where we take a break
                        ‘off the desk’ to bring you insightful conversations
                        with Tradeweb leaders and top industry experts.
                        Together, we’ll dive into the major trends shaping our
                        markets—like how workflows are evolving, game-changing
                        innovations, and the role
                        <a href="#"> data</a>,
                        <a href="#">
                          automation
                        </a>
                        and AI are playing in this evolution. It’s all about
                        exploring what’s happening now and what’s next in the
                        world of finance.
                      </p>
                    </div>
                    <footer className="mt-4">
                      <a
                        href="#"
                        aria-label="Off the Desk Podcast"
                        className="btn btn-dark-blue"
                      >
                        <div className="d-flex align-items-center">
                          Listen to Podcast
                        </div>
                      </a>
                    </footer>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <a
                  href="#"
                  className="margin-content-image-right col-12 col-featured-image order-first order-lg-last bg-size-cover bg-size-cover-md figure mb-4 mb-lg-0 js-sr-left d-block mnh-lg-315 px-sm-0 px-md-0 px-lg-0"
                  data-sr-id="17"
                >
                  <img
                    data-src={image}
                    className="content-promo-image"
                    alt="New podcast"
                    aria-labelledby="desc-655168aa-6877-4e70-a08d-e6aa406b7b44"
                    src={image}
                  />
                  <div
                    style={{ display: "none" }}
                    className="sr-only"
                    id="desc-655168aa-6877-4e70-a08d-e6aa406b7b44"
                  >
                    New podcast. Off the Desk
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OffTheDesk;
