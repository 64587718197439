import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
const SingleItem = ({ data, handleTradeChart }) => {
  const [SingleData, setSingleData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data?.crypto_currency_id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/trade/market/crypto/currency/view/${data?.crypto_currency_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data.data);
          setLoading(false);
        });
    }
  }, [data?.crypto_currency_id]);

  if (loading) {
    return;
  }

  return (
    <>
      <div
        className="flex py-14 cursor-pointer items-center hover:bg-gray-100 px-10 bottom-border"
        onClick={() =>
          handleTradeChart({
            MarketData: SingleData,
            marketSymbol: `${SingleData?.Symbol}${SingleData?.currency}`,
            Market: "Crypto",
          })
        }
      >
        <div className="flex-1 flex items-center">
          <LazyLoadImage
            alt="img"
            effect="blur"
            className="w-36 h-36"
            src={`${process.env.REACT_APP_API}/${SingleData?.image}`}
          />

          <span className="pl-10">
            {SingleData?.Symbol}
            {SingleData?.currency}
          </span>
        </div>
        {/* <div className="flex-1 text-kline-up">{ !(isNaN(getLivePrice?.current)) && getLivePrice?.current}</div> */}
      </div>
    </>
  );
};

export default SingleItem;
